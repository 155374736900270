const bo_routes = [
  {
    path: '',
    redirect: { name: 'Login' },
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import('@/views/backend/Login.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },

  // pendaftaran online
  {
    path: 'display-poli',
    name: 'DisplayPoli',
    component: () => import('@/views/display/DisplayPoli.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-penunjang',
    name: 'DisplayLab',
    component: () => import('@/views/display/DisplayLab.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian',
    name: 'DisplayAntrian',
    component: () => import('@/views/display/DisplayAntrian.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-anjungan-ranap',
    name: 'DisplayAnjunganRanap',
    component: () => import('@/views/display/DisplayAnjunganRanap.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-cetak',
    name: 'DisplayCetakAntrian',
    component: () => import('@/views/display/DisplayCetakAntrian.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-finger-print',
    name: 'DisplayFingerPrint',
    component: () => import('@/views/display/DisplayFingerPrint.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-admisi',
    name: 'DisplayAntrianAdmisi',
    component: () => import('@/views/display/DisplayAntrianAdmisi.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-perawat',
    name: 'DisplayAntrianPerawat',
    component: () => import('@/views/display/DisplayAntrianPerawat.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-farmasi',
    name: 'DisplayAntrianFarmasi',
    component: () => import('@/views/display/DisplayAntrianFarmasi.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-farmasi-ugd',
    name: 'DisplayAntrianFarmasiUGD',
    component: () => import('@/views/display/DisplayAntrianFarmasiUGD.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-check-in',
    name: 'DisplayCheckin',
    component: () => import('@/views/display/DisplayCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'input-kode-booking',
    name: 'InputKodeBooking',
    component: () => import('@/views/display/InputKodeBooking.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'scan-qr-code',
    name: 'ScanQRCode',
    component: () => import('@/views/display/ScanQRCode.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'scan-qr-code-surat',
    name: 'ScanQRCodeSurat',
    component: () => import('@/views/display/DisplayScanQRSurat.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'done-checkin/:pageSlug?',
    name: 'DoneCheckin',
    component: () => import('@/views/display/DoneCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'input-kode-bpjs',
    name: 'InputKodeBPJS',
    component: () => import('@/views/display/InputKodeBPJS.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  // pendaftaran online
  

  {
    path: 'dashboard/:pageSlug?',
    name: 'Dashboard',
    component: () => import('@/views/backend/Dashboard/Dashboard.vue'),
    meta: {
      menu: 'Dashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'dashboard-ugd/:pageSlug?',
    name: 'DashboardAdmisiUGD',
    component: () => import('@/views/backend/DashboardUGD/Dashboard.vue'),
    meta: {
      menu: 'DashboardAdmisiUGD',
      pageTitle: 'Dashboard Admisi UGD',
      objName: 'Dashboard Admisi UGD',
    },
  },
  {
    path: 'dashboard-ranap/:pageSlug?',
    name: 'DashboardRanap',
    component: () => import('@/views/backend/DashboardRanap/Dashboard.vue'),
    meta: {
      menu: 'DashboardRanap',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'hais/:pageSlug?',
    name: 'Hais',
    component: () => import('@/views/backend/Hais/List.vue'),
    meta: {
      menu: 'Hais',
      pageTitle: 'HAIs',
      objName: 'Hais',
    },
  },
  {
    path: 'keselamatan-pasien/:pageSlug?',
    name: 'KeselamatanPasien',
    component: () => import('@/views/backend/KeselamatanPasien/List.vue'),
    meta: {
      menu: 'KeselamatanPasien',
      pageTitle: 'Resiko Jatuh',
      objName: 'Resiko Jatuh',
    },
  },
  {
    path: 'laporan-insiden/:pageSlug?',
    name: 'LaporanInsiden',
    component: () => import('@/views/backend/LaporanInsiden/List.vue'),
    meta: {
      menu: 'LaporanInsiden',
      pageTitle: 'Laporan Insiden',
      objName: 'Laporan Insiden',
    },
  },
  {
    path: 'dokter/:pageSlug?/:pageId?',
    name: 'Dokter',
    component: () => import('@/views/backend/Dokter/List.vue'),
    meta: {
      menu: 'Dokter',
      pageTitle: 'Dokter',
      objName: 'Dokter',
    },
  },

  // {
  //   path: 'pasien/:pageSlug?',
  //   name: 'Pasien',
  //   component: () => import('@/views/backend/Pasien/List.vue'),
  //   meta: {
  //     menu: 'Pasien',
  //     pageTitle: 'Pasien',
  //     objName: 'Pasien',
  //   },
  // },

  {
    path: 'rekam-medis/:pageSlug?/:rmNo?/:typeKajian?',
    name: 'RekamMedis',
    component: () => import('@/views/backend/RekamMedis/List.vue'),
    meta: {
      menu: 'RekamMedis',
      pageTitle: 'Rekam Medis',
      objName: 'Rekam Medis',
    },
  },
  {
    path: 'registrasi-pasien/:pageSlug?',
    name: 'RegistrasiPasien',
    component: () => import('@/views/backend/RegistrasiPasien/List.vue'),
    meta: {
      menu: 'RegistrasiPasien',
      pageTitle: 'Registrasi Pasien',
      objName: 'Registrasi Pasien',
    },
  },
  {
    path: 'farmasi/:pageSlug?',
    name: 'Farmasi',
    component: () => import('@/views/backend/Farmasi/List.vue'),
    meta: {
      menu: 'Farmasi',
      pageTitle: 'Farmasi',
      objName: 'Farmasi',
    },
  },
  {
    path: 'verifikasi-rekam-medis/:pageSlug?',
    name: 'VerifikasiRM',
    component: () => import('@/views/backend/VerifikasiRM/Form.vue'),
    meta: {
      menu: 'VerifikasiRM',
      pageTitle: 'Verifikasi Rekam Medis',
      objName: 'Verifikasi Rekam Medis',
    },
  }, {
    path: 'verifikasi-upla/:pageSlug?',
    name: 'VerifikasiUpla',
    component: () => import('@/views/backend/VerifikasiUpla/Form.vue'),
    meta: {
      menu: 'VerifikasiUpla',
      pageTitle: 'Verifikasi Upla',
      objName: 'Verifikasi Upla',
    },
  },
  {
    path: 'laporan-management/:pageSlug?',
    name: 'LaporanManagement',
    component: () => import('@/views/backend/LaporanManagement/List.vue'),
    meta: {
      menu: 'LaporanManagement',
      pageTitle: 'Laporan Management',
      objName: 'Laporan Management',
    },
  },
  {
    path: 'report-billing/:pageSlug?',
    name: 'ReportBilling',
    component: () => import('@/views/backend/ReportBilling/List.vue'),
    meta: {
      menu: 'ReportBilling',
      pageTitle: 'Report Billing',
      objName: 'Report Billing',
    },
  },
  {
    path: 'master-hais/:pageSlug?',
    name: 'MHais',
    component: () => import('@/views/backend/MHais/List.vue'),
    meta: {
      menu: "MHais",
      pageTitle: 'Master Hais',
      hasCta: 'true',
      objName: 'Master Hais'
    },
  },
  {
    path: 'master-dosis/:pageSlug?',
    name: 'MDosis',
    component: () => import('@/views/backend/MDosis/List.vue'),
    meta: {
      menu: "MDosis",
      pageTitle: 'Master Dosis',
      hasCta: 'true',
      objName: 'Master Dosis'
    },
  },
  {
    path: 'follow-up/:pageSlug?',
    name: 'FollowUp',
    component: () => import('@/views/backend/FollowUp/Form.vue'),
    meta: {
      menu: "FollowUp",
      pageTitle: 'Follow Up',
      objName: 'Follow Up'
    },
  },
  {
    path: 'master-poli/:pageSlug?',
    name: 'MPoli',
    component: () => import('@/views/backend/MPoli/List.vue'),
    meta: {
      menu: "MPoli",
      pageTitle: 'Master Poli',
      hasCta: 'true',
      objName: 'Master Poli'
    },
  }, 
  {
    path: 'master-asal-rujukan/:pageSlug?',
    name: 'MAsalRujukan',
    component: () => import('@/views/backend/MAsalRujukan/List.vue'),
    meta: {
      menu: "MAsalRujukan",
      pageTitle: 'Master Asal Rujukan',
      hasCta: 'true',
      objName: 'Master Asal Rujukan'
    },
  }, 
  {
    path: 'master-obat/:pageSlug?',
    name: 'MObat',
    component: () => import('@/views/backend/MObat/List.vue'),
    meta: {
      menu: "MObat",
      pageTitle: 'Master Obat',
      hasCta: 'true',
      objName: 'Master Obat'
    },
  },
  {
    path: 'master-alat-kesehatan/:pageSlug?',
    name: 'MAlatKesehatan',
    component: () => import('@/views/backend/MAlatKesehatan/List.vue'),
    meta: {
      menu: "MAlatKesehatan",
      pageTitle: 'Master Alat Kesehatan',
      hasCta: 'true',
      objName: 'Master Alat Kesehatan'
    },
  },
  {
    path: 'master-alat-infus/:pageSlug?',
    name: 'MAlatInfus',
    component: () => import('@/views/backend/MAlatInfus/List.vue'),
    meta: {
      menu: "MAlatInfus",
      pageTitle: 'Master Alat Infus',
      hasCta: 'true',
      objName: 'Master Alat Infus'
    },
  },
  {
    path: 'master-radiologi/:pageSlug?',
    name: 'MRadiologi',
    component: () => import('@/views/backend/MRadiologi/List.vue'),
    meta: {
      menu: "MRadiologi",
      pageTitle: 'Master Radiologi',
      hasCta: 'true',
      objName: 'Master Radiologi'
    },
  },
  {
    path: 'master-fisioterapi/:pageSlug?',
    name: 'MFisioterapi',
    component: () => import('@/views/backend/MFisioterapi/List.vue'),
    meta: {
      menu: "MFisioterapi",
      pageTitle: 'Master Fisioterapi',
      hasCta: 'true',
      objName: 'Master Fisioterapi'
    },
  },
  
  {
    path: 'master-fisioterapi-head/:pageSlug?',
    name: 'MFisioterapiHead',
    component: () => import('@/views/backend/MFisioterapiHead/List.vue'),
    meta: {
      menu: "MFisioterapiHead",
      pageTitle: 'Master Fisioterapi Head',
      hasCta: 'true',
      objName: 'Master Fisioterapi Head'
    },
  },
  {
    path: 'master-insiden/:pageSlug?',
    name: 'MInsiden',
    component: () => import('@/views/backend/MInsiden/List.vue'),
    meta: {
      menu: "MInsiden",
      pageTitle: 'Master Insiden',
      hasCta: 'true',
      objName: 'Master Insiden'
    },
  },
  {
    path: 'master-inform-consent/:pageSlug?',
    name: 'MInformConsent',
    component: () => import('@/views/backend/MInformConsent/List.vue'),
    meta: {
      menu: "MInformConsent",
      pageTitle: 'Master Inform Consent',
      objName: 'Master Inform Consent'
    },
  },
  {
    path: 'master-zipcode/:pageSlug?',
    name: 'MZipCode',
    component: () => import('@/views/backend/MZipCode/List.vue'),
    meta: {
      menu: "MZipCode",
      pageTitle: 'Master Zip Code',
      hasCta: 'true',
      objName: 'Master Zip Code'
    },
  },
  {
    path: 'master-diagnosa-gizi/:pageSlug?',
    name: 'MICDGizi',
    component: () => import('@/views/backend/MICDGizi/List.vue'),
    meta: {
      menu: "MICDGizi",
      pageTitle: 'Master Diagnosa Gizi',
      hasCta: 'true',
      objName: 'Master Diagnosa Gizi'
    },
  },
  {
    path: 'master-tindakan-gizi/:pageSlug?',
    name: 'MICDGiziTindakan',
    component: () => import('@/views/backend/MICDGiziTindakan/List.vue'),
    meta: {
      menu: "MICDGiziTindakan",
      pageTitle: 'Master Intervensi Gizi',
      hasCta: 'true',
      objName: 'Master Intervensi Gizi'
    },
  },
  {
    path: 'master-tujuan-gizi/:pageSlug?',
    name: 'MICDGiziTujuan',
    component: () => import('@/views/backend/MICDGiziTujuan/List.vue'),
    meta: {
      menu: "MICDGiziTujuan",
      pageTitle: 'Master Tujuan Gizi',
      hasCta: 'true',
      objName: 'Master Tujuan Gizi'
    },
  },
  {
    path: 'master-etimologi-gizi/:pageSlug?',
    name: 'MICDGiziEtimologi',
    component: () => import('@/views/backend/MICDGiziEtimologi/List.vue'),
    meta: {
      menu: "MICDGiziEtimologi",
      pageTitle: 'Master Etimologi Gizi',
      hasCta: 'true',
      objName: 'Master Etimologi Gizi'
    },
  },
  {
    path: 'master-problem-gizi/:pageSlug?',
    name: 'MICDGiziProblem',
    component: () => import('@/views/backend/MICDGiziProblem/List.vue'),
    meta: {
      menu: "MICDGiziProblem",
      pageTitle: 'Master Etimologi Gizi',
      hasCta: 'true',
      objName: 'Master Etimologi Gizi'
    },
  },
  {
    path: 'master-sign-gizi/:pageSlug?',
    name: 'MICDGiziSign',
    component: () => import('@/views/backend/MICDGiziSign/List.vue'),
    meta: {
      menu: "MICDGiziSign",
      pageTitle: 'Master Sign Gizi',
      hasCta: 'true',
      objName: 'Master Sign Gizi'
    },
  },
  {
    path: 'master-jenis-diit/:pageSlug?',
    name: 'MJenisDiit',
    component: () => import('@/views/backend/MJenisDiit/List.vue'),
    meta: {
      menu: "MJenisDiit",
      pageTitle: 'Master Jenis Diit',
      hasCta: 'true',
      objName: 'Master Jenis Diit'
    },
  },
  {
    path: 'master-bentuk-makanan/:pageSlug?',
    name: 'MBentukMakanan',
    component: () => import('@/views/backend/MBentukMakanan/List.vue'),
    meta: {
      menu: "MBentukMakanan",
      pageTitle: 'Master Bentuk Makanan',
      hasCta: 'true',
      objName: 'Master Bentuk Makanan'
    },
  },
  
  {
    path: 'master-icd-9/:pageSlug?',
    name: 'MICD9',
    component: () => import('@/views/backend/MICD9/List.vue'),
    meta: {
      menu: "MICD9",
      pageTitle: 'Master ICD9',
      hasCta: 'true',
      objName: 'Master ICD9'
    },
  }, 
  {
    path: 'master-icd-10/:pageSlug?',
    name: 'MICD10',
    component: () => import('@/views/backend/MICD10/List.vue'),
    meta: {
      menu: "MICD10",
      pageTitle: 'Master ICD10',
      hasCta: 'true',
      objName: 'Master ICD10'
    },
  },
  {
    path: 'master-tindakan-dokter/:pageSlug?',
    name: 'MTindakanDokter',
    component: () => import('@/views/backend/MTindakanDokter/List.vue'),
    meta: {
      menu: "MTindakanDokter",
      pageTitle: 'Tindakan Dokter',
      hasCta: 'true',
      objName: 'Tindakan Dokter'
    },
  },
  {
    path: 'master-tindakan-perawat/:pageSlug?',
    name: 'MTindakanPerawat',
    component: () => import('@/views/backend/MTindakanPerawat/List.vue'),
    meta: {
      menu: "MTindakanPerawat",
      pageTitle: 'Tindakan Perawat',
      hasCta: 'true',
      objName: 'Tindakan Perawat'
    },
  },
  
  {
    path: 'master-icd-9/:pageSlug?',
    name: 'MICD9UPLA',
    component: () => import('@/views/backend/MICD9UPLA/List.vue'),
    meta: {
      menu: "MICD9UPLA",
      pageTitle: 'Master ICD9 UPLA',
      hasCta: 'true',
      objName: 'Master ICD9 UPLA'
    },
  }, 
  {
    path: 'master-icd-10/:pageSlug?',
    name: 'MICD10UPLA',
    component: () => import('@/views/backend/MICD10UPLA/List.vue'),
    meta: {
      menu: "MICD10UPLA",
      pageTitle: 'Master ICD10 UPLA',
      hasCta: 'true',
      objName: 'Master ICD10 UPLA'
    },
  },

  {
    path: 'master-alat-bantu-tarik/:pageSlug?',
    name: 'MAlatBantuTarik',
    component: () => import('@/views/backend/MAlatBantuTarik/List.vue'),
    meta: {
      menu: "MAlatBantuTarik",
      pageTitle: 'Master Alat Bantu Tarik',
      hasCta: 'true',
      objName: 'Master Alat Bantu Tarik'
    },
  }, 
  {
    path: 'master-tindakan-fisioterapi/:pageSlug?',
    name: 'MTindakan',
    component: () => import('@/views/backend/MTindakan/List.vue'),
    meta: {
      menu: "MTindakan",
      pageTitle: 'Master Tindakan Fisioterapi',
      hasCta: 'true',
      objName: 'Master Tindakan Fisioterapi'
    },
  }, 
  {
    path: 'master-cara-pembayaran/:pageSlug?',
    name: 'MCaraPembayaran',
    component: () => import('@/views/backend/MCaraPembayaran/List.vue'),
    meta: {
      menu: "MCaraPembayaran",
      pageTitle: 'Master Cara Pembayaran',
      hasCta: 'true',
      objName: 'Master Cara Pembayaran'
    },
  },
  {
    path: 'master-cairan/:pageSlug?',
    name: 'MCairan',
    component: () => import('@/views/backend/MCairan/List.vue'),
    meta: {
      menu: "MCairan",
      pageTitle: 'Master Cairan Infus',
      hasCta: 'true',
      objName: 'Master Cairan Infus'
    },
  },
  {
    path: 'master-darah-balance-cairan/:pageSlug?',
    name: 'MDarahBalanceCairan',
    component: () => import('@/views/backend/MDarahBalanceCairan/List.vue'),
    meta: {
      menu: "MDarahBalanceCairan",
      pageTitle: 'Master Darah Balance Cairan',
      hasCta: 'true',
      objName: 'Master Darah Balance Cairan'
    },
  },
  {
    path: 'master-kesadaran/:pageSlug?',
    name: 'MKesadaran',
    component: () => import('@/views/backend/MKesadaran/List.vue'),
    meta: {
      menu: "MKesadaran",
      pageTitle: 'Master Kesadaran',
      hasCta: 'true',
      objName: 'Master Kesadaran'
    },
  },
  {
    path: 'master-pekerjaan/:pageSlug?',
    name: 'MPendidikan',
    component: () => import('@/views/backend/MPendidikan/List.vue'),
    meta: {
      menu: "MPendidikan",
      pageTitle: 'Master Pendidikan',
      hasCta: 'true',
      objName: 'Master Pendidikan'
    },
  },
  {
    path: 'master-pekerjaan/:pageSlug?',
    name: 'MPekerjaan',
    component: () => import('@/views/backend/MPekerjaan/List.vue'),
    meta: {
      menu: "MPekerjaan",
      pageTitle: 'Master Pekerjaan',
      hasCta: 'true',
      objName: 'Master Pekerjaan'
    },
  },
  {
    path: 'master-laboratorium/:pageSlug?',
    name: 'MLab',
    component: () => import('@/views/backend/MLab/List.vue'),
    meta: {
      menu: "MLab",
      pageTitle: 'Master Tindakan Laboratorium',
      hasCta: 'true',
      objName: 'Master Tindakan Laboratorium'
    },
  },
  {
    path: 'master-jenis-tindakan-operasi/:pageSlug?',
    name: 'MRanapJenisTindakanOperasi',
    component: () => import('@/views/backend/MRanapJenisTindakanOperasi/List.vue'),
    meta: {
      menu: "MRanapJenisTindakanOperasi",
      pageTitle: 'Master Jenis Tindakan Operasi',
      hasCta: 'true',
      objName: 'Master Jenis Tindakan Operasi'
    },
  },
  {
    path: 'master-penyakit-menular/:pageSlug?',
    name: 'MRanapPenyakitMenular',
    component: () => import('@/views/backend/MRanapPenyakitMenular/List.vue'),
    meta: {
      menu: "MRanapPenyakitMenular",
      pageTitle: 'Master Penyakit Menular',
      hasCta: 'true',
      objName: 'Master Penyakit Menular'
    },
  },
  {
    path: 'master-implant/:pageSlug?',
    name: 'MRanapImplant',
    component: () => import('@/views/backend/MRanapImplant/List.vue'),
    meta: {
      menu: "MRanapImplant",
      pageTitle: 'Master Ranap Implant',
      hasCta: 'true',
      objName: 'Master Ranap Implant'
    },
  },
  {
    path: 'master-tindakan-operasi/:pageSlug?',
    name: 'MRanapTindakanOperasi',
    component: () => import('@/views/backend/MRanapTindakanOperasi/List.vue'),
    meta: {
      menu: "MRanapTindakanOperasi",
      pageTitle: 'Master Tindakan Operasi',
      hasCta: 'true',
      objName: 'Master Tindakan Operasi'
    },
  },
  {
    path: 'master-sub-laboratorium/:pageSlug?',
    name: 'MSubLab',
    component: () => import('@/views/backend/MSubLab/List.vue'),
    meta: {
      menu: "MSubLab",
      pageTitle: 'Master Sub Laboratorium',
      hasCta: 'true',
      objName: 'Master Sub Laboratorium'
    },
  },
  {
    path: 'master-agama/:pageSlug?',
    name: 'MAgama',
    component: () => import('@/views/backend/MAgama/List.vue'),
    meta: {
      menu: "MAgama",
      pageTitle: 'Master Agama',
      hasCta: 'true',
      objName: 'Master Agama'
    },
  },
  {
    path: 'master-sdki/:pageSlug?',
    name: 'MSDKI',
    component: () => import('@/views/backend/MSDKI/List.vue'),
    meta: {
      menu: "MSDKI",
      pageTitle: 'Master Diagnosa SDKI',
      hasCta: 'true',
      objName: 'Master Diagnosa SDKI'
    },
  },
  {
    path: 'master-kategori-sdki/:pageSlug?',
    name: 'MKategoriSDKI',
    component: () => import('@/views/backend/MKategoriSDKI/List.vue'),
    meta: {
      menu: "MKategoriSDKI",
      pageTitle: 'Master Kategori SDKI',
      hasCta: 'true',
      objName: 'Master Kategori SDKI'
    },
  },
  {
    path: 'master-sub-kategori-sdki/:pageSlug?',
    name: 'MSubKategoriSDKI',
    component: () => import('@/views/backend/MSubKategoriSDKI/List.vue'),
    meta: {
      menu: "MSubKategoriSDKI",
      pageTitle: 'Master Sub Kategori SDKI',
      hasCta: 'true',
      objName: 'Master Sub Kategori SDKI'
    },
  },
  {
    path: 'master-slki/:pageSlug?',
    name: 'MSLKI',
    component: () => import('@/views/backend/MSLKI/List.vue'),
    meta: {
      menu: "MSLKI",
      pageTitle: 'Master Diagnosa SLKI',
      hasCta: 'true',
      objName: 'Master Diagnosa SLKI'
    },
  },
  {
    path: 'master-kriteria-hasil-slki/:pageSlug?',
    name: 'MKriteriaSLKI',
    component: () => import('@/views/backend/MKriteriaSLKI/List.vue'),
    meta: {
      menu: "MKriteriaSLKI",
      pageTitle: 'Master Kriteria SLKI',
      hasCta: 'true',
      objName: 'Master Kriteria SLKI'
    },
  },
  {
    path: 'master-siki/:pageSlug?',
    name: 'MSIKI',
    component: () => import('@/views/backend/MSIKI/List.vue'),
    meta: {
      menu: "MSIKI",
      pageTitle: 'Master SIKI',
      hasCta: 'true',
      objName: 'Master SIKI'
    },
  },
  {
    path: 'master-type-satuan/:pageSlug?',
    name: 'MTypeSatuan',
    component: () => import('@/views/backend/MTypeSatuan/List.vue'),
    meta: {
      menu: "MTypeSatuan",
      pageTitle: 'Master Type Satuan',
      hasCta: 'true',
      objName: 'Master Type Satuan'
    },
  },
  {
    path: 'master-fakes-rujukan/:pageSlug?',
    name: 'MRoFakesRujukan',
    component: () => import('@/views/backend/MRoFakesRujukan/List.vue'),
    meta: {
      menu: "MRoFakesRujukan",
      pageTitle: 'Master Fakes Rujukan',
      hasCta: 'true',
      objName: 'Master Fakes Rujukan'
    },
  },
  {
    path: 'master-poli-rujukan/:pageSlug?',
    name: 'MRoPoliRujukan',
    component: () => import('@/views/backend/MRoPoliRujukan/List.vue'),
    meta: {
      menu: "MRoPoliRujukan",
      pageTitle: 'Master Poli Rujukan',
      hasCta: 'true',
      objName: 'Master Poli Rujukan'
    },
  },
  {
    path: 'master-dokter-rujukan/:pageSlug?',
    name: 'MRoDokterRujukan',
    component: () => import('@/views/backend/MRoDokterRujukan/List.vue'),
    meta: {
      menu: "MRoDokterRujukan",
      pageTitle: 'Master Dokter Rujukan',
      hasCta: 'true',
      objName: 'Master Dokter Rujukan'
    },
  },
  {
    path: 'master-suku/:pageSlug?',
    name: 'MRoSuku',
    component: () => import('@/views/backend/MRoSuku/List.vue'),
    meta: {
      menu: "MRoSuku",
      pageTitle: 'Master Suku',
      hasCta: 'true',
      objName: 'Master Suku'
    },
  },
  {
    path: 'master-hari-libur',
    name: 'MHariLibur',
    component: () => import('@/views/backend/MHariLibur/List.vue'),
    meta: {
      menu: "MHariLibur",
      pageTitle: 'Master Hari Libur',
      objName: 'Master Hari Libur'
    },
  },
  {
    path: 'master-riwayat-alergi/:pageSlug?',
    name: 'MRiwayatAlergi',
    component: () => import('@/views/backend/MRiwayatAlergi/List.vue'),
    meta: {
      menu: "MRiwayatAlergi",
      pageTitle: 'Master Riwayat Alergi',
      hasCta: 'true',
      objName: 'Master Riwayat Alergi'
    },
  },
  {
    path: 'master-vendor/:pageSlug?',
    name: 'MVendor',
    component: () => import('@/views/backend/MVendor/List.vue'),
    meta: {
      menu: "MVendor",
      pageTitle: 'Master Vendor',
      hasCta: 'true',
      objName: 'Master Vendor'
    },
  },
  {
    path: 'master-rekening-bank/:pageSlug?',
    name: 'MRekeningBank',
    component: () => import('@/views/backend/MRekeningBank/List.vue'),
    meta: {
      menu: "MRekeningBank",
      pageTitle: 'Master Rekening Bank',
      hasCta: 'true',
      objName: 'Master Rekening Bank'
    },
  },
  {
    path: 'master-bank/:pageSlug?',
    name: 'MBank',
    component: () => import('@/views/backend/MBank/List.vue'),
    meta: {
      menu: "MBank",
      pageTitle: 'Master Bank',
      hasCta: 'true',
      objName: 'Master Bank'
    },
  },
  {
    path: 'master-asal-darah/:pageSlug?',
    name: 'MAsalDarah',
    component: () => import('@/views/backend/MAsalDarah/List.vue'),
    meta: {
      menu: "MAsalDarah",
      pageTitle: 'Master Asal Darah',
      hasCta: 'true',
      objName: 'Master Asal Darah'
    },
  },
  {
    path: 'master-fasilitas/:pageSlug?',
    name: 'MFasilitas',
    component: () => import('@/views/backend/MFasilitas/List.vue'),
    meta: {
      menu: "MFasilitas",
      pageTitle: 'Master Fasilitas',
      hasCta: 'true',
      objName: 'Master Fasilitas'
    },
  },

  {
    path: 'account-setting',
    name: 'AccountSetting',
    component: () => import('@/views/backend/AccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/User/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'UserLevel',
    component: () => import('@/views/backend/UserLevel/List.vue'),
    meta: {
      menu: 'UserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'static-settings',
    name: 'StaticSettings',
    component: () => import('@/views/backend/StaticSettings/List.vue'),
    meta: {
      menu: 'StaticSettings',
      pageTitle: 'Static Settings',
      objName: 'Static Settings'
    },
  },
  {
    path: 'audit-trail/:pageSlug?',
    name: 'AuditTrail',
    component: () => import('@/views/backend/AuditTrail/AuditTrail.vue'),
    meta: {
      menu: 'AuditTrail',
      pageTitle: 'Audit Trail',
      objName: 'Audit Trail'
    },
  },

  {
    path: 'pasien-lama/:pageSlug?',
    name: 'RoPasien',
    component: () => import('@/views/backend/RoPasien/List.vue'),
    meta: {
      menu: 'RoPasien',
      pageTitle: 'Admisi - Pasien',
      objName: 'Admisi - Pasien',
    },
  },
  
  {
    path: 'pasien/:pageSlug?',
    name: 'RoPasienNew',
    component: () => import('@/views/backend/RoPasienNew/List.vue'),
    meta: {
      menu: 'RoPasienNew',
      pageTitle: 'Admisi - Pasien',
      objName: 'Admisi - Pasien',
    },
  },

  {
    path: 'admisi-reservasi/:pageSlug?',
    name: 'RoReservasi',
    component: () => import('@/views/backend/RoReservasi/List.vue'),
    meta: {
      menu: 'RoReservasi',
      pageTitle: 'Admisi - Reservasi',
      objName: 'Admisi - Reservasi'
    },
  },

  {
    path: 'kasir',
    name: 'RoKasir',
    component: () => import('@/views/backend/RoKasir/List.vue'),
    meta: {
      menu: 'RoKasir',
      pageTitle: 'Kasir',
      objName: 'Kasir'
    },
  },
  
  {
    path: 'audio-antrean/:pageSlug?',
    name: 'AntreanRoAudio',
    component: () => import('@/views/backend/AntreanRoAudio/List.vue'),
    meta: {
      menu: 'AntreanRoAudio',
      pageTitle: 'Antrean - Audio',
      objName: 'Antrean - Audio',
      hasCta: 'true',
    },
  },
  {
    path: 'unit-tujuan/:pageSlug?',
    name: 'AntreanRoUnit',
    component: () => import('@/views/backend/AntreanRoUnit/List.vue'),
    meta: {
      menu: 'AntreanRoUnit',
      pageTitle: 'Antrean - Unit Tujuan',
      objName: 'Antrean - Unit Tujuan',
      hasCta: 'true',
    },
  },
  {
    path: 'group-antrean/:pageSlug?',
    name: 'AntreanRoGroup',
    component: () => import('@/views/backend/AntreanRoGroup/List.vue'),
    meta: {
      menu: 'AntreanRoGroup',
      pageTitle: 'Antrean - Group Antrean',
      objName: 'Antrean - Group Antrean',
      hasCta: 'true',
    },
  },
  {
    path: 'display-layar/:pageSlug?',
    name: 'AntreanRoDisplayLayar',
    component: () => import('@/views/backend/AntreanRoDisplayLayar/List.vue'),
    meta: {
      menu: 'AntreanRoDisplayLayar',
      pageTitle: 'Antrean - Display Antrean',
      objName: 'Antrean - Display Antrean',
    },
  },
  {
    path: 'jadwal-dokter',
    name: 'RoJadwalDokter',
    component: () => import('@/views/backend/RoJadwalDokter/List.vue'),
    meta: {
      menu: 'RoJadwalDokter',
      pageTitle: 'Jadwal Dokter',
      objName: 'Jadwal Dokter',
    },
  },
  {
    path: 'rujukan/:pageSlug?',
    name: 'RoRujukan',
    component: () => import('@/views/backend/RoRujukan/List.vue'),
    meta: {
      menu: 'RoRujukan',
      pageTitle: 'Rujukan',
      objName: 'Rujukan'
    },
  },
  {
    path: 'rencana-kontrol/:pageSlug?',
    name: 'RoRencanaKontrol',
    component: () => import('@/views/backend/RoRencanaKontrol/List.vue'),
    meta: {
      menu: 'RoRencanaKontrol',
      pageTitle: 'Rencana Kontrol',
      objName: 'Rencana Kontrol'
    },
  },
  {
    path: 'post-opname/:pageSlug?',
    name: 'RanapPostOpname',
    component: () => import('@/views/backend/RanapPostOpname/List.vue'),
    meta: {
      menu: 'RanapPostOpname',
      pageTitle: 'Post Opname',
      objName: 'Post Opname'
    },
  },  
  {
    path: 'admisi-antrean',
    name: 'RoAdmisiAntrean',
    component: () => import('@/views/backend/RoAdmisiAntrean/List.vue'),
    meta: {
      menu: 'RoAdmisiAntrean',
      pageTitle: 'Admisi Antrean',
      objName: 'Admisi Antrean',
    },
  },
  {
    path: 'ranap-admisi-antrean',
    name: 'RanapAdmisiAntrean',
    component: () => import('@/views/backend/RanapAdmisiAntrean/List.vue'),
    meta: {
      menu: 'RanapAdmisiAntrean',
      pageTitle: 'Admisi Antrean',
      objName: 'Admisi Antrean',
    },
  },
  {
    path: 'informasi-admisi-antrean',
    name: 'InformasiAdmisiAntrean',
    component: () => import('@/views/backend/InformasiAdmisiAntrean/List.vue'),
    meta: {
      menu: 'InformasiAdmisiAntrean',
      pageTitle: 'Admisi Antrean',
      objName: 'Admisi Antrean',
    },
  },
  {
    path: 'billing',
    name: 'RoBilling',
    component: () => import('@/views/backend/RoBilling/List.vue'),
    meta: {
      menu: 'RoBilling',
      pageTitle: 'Billing',
      objName: 'Billing',
    },
  },
  {
    path: 'billing-poliklinik',
    name: 'BoBillingPoliklinik',
    component: () => import('@/views/backend/BoBillingPoliklinik/List.vue'),
    meta: {
      menu: 'BoBillingPoliklinik',
      pageTitle: 'Billing',
      objName: 'Billing',
    },
  },
  {
    path: 'billing-eksternal-poliklinik',
    name: 'BoBillingEksternalPoliklinik',
    component: () => import('@/views/backend/BoBillingEksternalPoliklinik/List.vue'),
    meta: {
      menu: 'BoBillingEksternalPoliklinik',
      pageTitle: 'Billing Eksternal',
      objName: 'Billing Eksternal',
    },
  },
  {
    path: 'billing-medical-report-poliklinik',
    name: 'BoBillingMedicalReportPoliklinik',
    component: () => import('@/views/backend/BoBillingMedicalReportPoliklinik/List.vue'),
    meta: {
      menu: 'BoBillingMedicalReportPoliklinik',
      pageTitle: 'Billing Medical Report',
      objName: 'Billing Medical Report',
    },
  },

  {
    path: 'kasir-poliklinik',
    name: 'BoKasirPoliklinik',
    component: () => import('@/views/backend/BoKasirPoliklinik/List.vue'),
    meta: {
      menu: 'BoKasirPoliklinik',
      pageTitle: 'Kasir',
      objName: 'Kasir',
    },
  },
  {
    path: 'kasir-eksternal-poliklinik',
    name: 'BoKasirEksternalPoliklinik',
    component: () => import('@/views/backend/BoKasirEksternalPoliklinik/List.vue'),
    meta: {
      menu: 'BoKasirEksternalPoliklinik',
      pageTitle: 'Kasir Eksternal',
      objName: 'Kasir Eksternal',
    },
  },
  {
    path: 'kasir-medical-report-poliklinik',
    name: 'BoKasirMedicalReportPoliklinik',
    component: () => import('@/views/backend/BoKasirMedicalReportPoliklinik/List.vue'),
    meta: {
      menu: 'BoKasirMedicalReportPoliklinik',
      pageTitle: 'Kasir Medical Report',
      objName: 'Kasir Medical Report',
    },
  },
  {
    path: 'billing-ugd',
    name: 'BoBillingUGD',
    component: () => import('@/views/backend/BoBillingUGD/List.vue'),
    meta: {
      menu: 'BoBillingUGD',
      pageTitle: 'Billing',
      objName: 'Billing',
    },
  },
  {
    path: 'billing-eksternal-ugd',
    name: 'BoBillingEksternalUGD',
    component: () => import('@/views/backend/BoBillingEksternalUGD/List.vue'),
    meta: {
      menu: 'BoBillingEksternalUGD',
      pageTitle: 'Billing Eksternal',
      objName: 'Billing Eksternal',
    },
  },
  {
    path: 'billing-medical-report-ugd',
    name: 'BoBillingMedicalReportUGD',
    component: () => import('@/views/backend/BoBillingMedicalReportUGD/List.vue'),
    meta: {
      menu: 'BoBillingMedicalReportUGD',
      pageTitle: 'Billing Medical Report',
      objName: 'Billing Medical Report',
    },
  },
  {
    path: 'kasir-ugd',
    name: 'BoKasirUGD',
    component: () => import('@/views/backend/BoKasirUGD/List.vue'),
    meta: {
      menu: 'BoKasirUGD',
      pageTitle: 'Kasir',
      objName: 'Kasir',
    },
  },
  {
    path: 'revisi-akuntansi-ugd',
    name: 'BoRevisiAkuntansiUGD',
    component: () => import('@/views/backend/BoRevisiAkuntansiUGD/List.vue'),
    meta: {
      menu: 'BoRevisiAkuntansiUGD',
      pageTitle: 'Revisi Akuntansi',
      objName: 'Revisi Akuntansi',
    },
  },
  {
    path: 'revisi-akuntansi-poli',
    name: 'BoRevisiAkuntansiPoliklinik',
    component: () => import('@/views/backend/BoRevisiAkuntansiPoliklinik/List.vue'),
    meta: {
      menu: 'BoRevisiAkuntansiPoliklinik',
      pageTitle: 'Revisi Akuntansi',
      objName: 'Revisi Akuntansi',
    },
  },
  {
    path: 'kasir-eksternal-ugd',
    name: 'BoKasirEksternalUGD',
    component: () => import('@/views/backend/BoKasirEksternalUGD/List.vue'),
    meta: {
      menu: 'BoKasirEksternalUGD',
      pageTitle: 'Kasir Eksternal',
      objName: 'Kasir Eksternal',
    },
  },
  {
    path: 'kasir-medical-report-ugd',
    name: 'BoKasirMedicalReportUGD',
    component: () => import('@/views/backend/BoKasirMedicalReportUGD/List.vue'),
    meta: {
      menu: 'BoKasirMedicalReportUGD',
      pageTitle: 'Kasir Medical Report',
      objName: 'Kasir Medical Report',
    },
  },
  {
    path: 'billing-ranap',
    name: 'BoBillingRanap',
    component: () => import('@/views/backend/BoBillingRanap/List.vue'),
    meta: {
      menu: 'BoBillingRanap',
      pageTitle: 'Billing',
      objName: 'Billing',
    },
  },
  {
    path: 'billing-eksternal-ranap',
    name: 'BoBillingEksternalRanap',
    component: () => import('@/views/backend/BoBillingEksternalRanap/List.vue'),
    meta: {
      menu: 'BoBillingEksternalRanap',
      pageTitle: 'Billing Eksternal',
      objName: 'Billing Eksternal',
    },
  },
  {
    path: 'billing-medical-report-ranap',
    name: 'BoBillingMedicalReportRanap',
    component: () => import('@/views/backend/BoBillingMedicalReportRanap/List.vue'),
    meta: {
      menu: 'BoBillingMedicalReportRanap',
      pageTitle: 'Billing Medical Report',
      objName: 'Billing Medical Report',
    },
  },
  {
    path: 'kasir-rawat-inap',
    name: 'BoKasirRanap',
    component: () => import('@/views/backend/BoKasirRanap/List.vue'),
    meta: {
      menu: 'BoKasirRanap',
      pageTitle: 'Kasir',
      objName: 'Kasir',
    },
  },
  {
    path: 'kasir-eksternal-rawat-inap',
    name: 'BoKasirEksternalRanap',
    component: () => import('@/views/backend/BoKasirEksternalRanap/List.vue'),
    meta: {
      menu: 'BoKasirEksternalRanap',
      pageTitle: 'Kasir Eksternal',
      objName: 'Kasir Eksternal',
    },
  },
  {
    path: 'kasir-medical-report-rawat-inap',
    name: 'BoKasirMedicalReportRanap',
    component: () => import('@/views/backend/BoKasirMedicalReportRanap/List.vue'),
    meta: {
      menu: 'BoKasirMedicalReportRanap',
      pageTitle: 'Kasir Medical Report',
      objName: 'Kasir Medical Report',
    },
  },
  {
    path: 'penagihan-piutang/:pageSlug?/:pageId?',
    name: 'BoPenagihanPiutang',
    component: () => import('@/views/backend/BoPenagihanPiutang/List.vue'),
    meta: {
      menu: 'BoPenagihanPiutang',
      pageTitle: 'Penagihan Piutang',
      objName: 'Penagihan Piutang',
      hasCta: 'true'
    },
  },
  {
    path: 'pembayaran-piutang/:pageSlug?/:pageId?',
    name: 'BoPembayaranPiutang',
    component: () => import('@/views/backend/BoPembayaranPiutang/List.vue'),
    meta: {
      menu: 'BoPembayaranPiutang',
      pageTitle: 'Pembayaran Piutang',
      objName: 'Pembayaran Piutang',
      hasCta: 'true'
    },
  },
  {
    path: 'Notifikasi',
    name: 'Notification',
    component: () => import('@/views/backend/Notification/List.vue'),
    meta: {
      menu: 'Notification',
      pageTitle: 'Notifikasi',
      objName: 'Notifikasi',
    },
  },

  {
    path: 'bpjs-peserta',
    name: 'BPJSPeserta',
    component: () => import('@/views/backend/BPJSPeserta/List.vue'),
    meta: {
      menu: 'BPJSPeserta',
      pageTitle: 'BPJS Peserta',
      objName: 'BPJS Peserta',
    },
  },

  {
    path: 'bpjs-referensi',
    name: 'BPJSReferensi',
    component: () => import('@/views/backend/BPJSReferensi/List.vue'),
    meta: {
      menu: 'BPJSReferensi',
      pageTitle: 'BPJS Referensi',
      objName: 'BPJS Referensi',
    },
  },

  {
    path: 'bpjs-log-antrean',
    name: 'BPJSLogAntrean',
    component: () => import('@/views/backend/BPJSLogAntrean/List.vue'),
    meta: {
      menu: 'BPJSLogAntrean',
      pageTitle: 'BPJS Log Antrean',
      objName: 'BPJS Log Antrean',
    },
  },

  {
    path: 'bpjs-monitoring',
    name: 'BPJSMonitoring',
    component: () => import('@/views/backend/BPJSMonitoring/List.vue'),
    meta: {
      menu: 'BPJSMonitoring',
      pageTitle: 'BPJS Monitoring',
      objName: 'BPJS Monitoring',
    },
  },

  {
    path: 'bpjs-sep',
    name: 'BPJSSEP',
    component: () => import('@/views/backend/BPJSSEP/List.vue'),
    meta: {
      menu: 'BPJSSEP',
      pageTitle: 'BPJS SEP',
      objName: 'BPJS SEP',
    },
  },

  {
    path: 'bpjs-referensi-antrean',
    name: 'BPJSRefrensiAntrean',
    component: () => import('@/views/backend/BPJSRefrensiAntrean/List.vue'),
    meta: {
      menu: 'BPJSRefrensiAntrean',
      pageTitle: 'BPJS Referensi Antrean',
      objName: 'BPJS Referensi Antrean',
    },
  },
  
  {
    path: 'bpjs-rujukan',
    name: 'BPJSRujukan',
    component: () => import('@/views/backend/BPJSRujukan/List.vue'),
    meta: {
      menu: 'BPJSRujukan',
      pageTitle: 'BPJS Rujukan',
      objName: 'BPJS Rujukan',
    },
  },

  {
    path: 'bpjs-prb',
    name: 'BPJSPRB',
    component: () => import('@/views/backend/BPJSPRB/List.vue'),
    meta: {
      menu: 'BPJSPRB',
      pageTitle: 'BPJS PRB',
      objName: 'BPJS PRB',
    },
  },

  {
    path: 'bpjs-rencana-kontrol',
    name: 'BPJSRencanaKontrol',
    component: () => import('@/views/backend/BPJSRencanaKontrol/List.vue'),
    meta: {
      menu: 'BPJSRencanaKontrol',
      pageTitle: 'BPJS Rencana Kontrol',
      objName: 'BPJS Rencana Kontrol',
    },
  },

  {
    path: 'bpjs-data-sep/:pageSlug?',
    name: 'BPJSDataSEP',
    component: () => import('@/views/backend/BPJSDataSEP/List.vue'),
    meta: {
      menu: 'BPJSDataSEP',
      pageTitle: 'Data SEP',
      objName: 'Data SEP',
      hasCta: 'true',
    },
  },

  {
    path: 'bpjs-lpk/:pageSlug?',
    name: 'BPJSLPK',
    component: () => import('@/views/backend/BPJSLPK/List.vue'),
    meta: {
      menu: 'BPJSLPK',
      pageTitle: 'BPJS Data LPK',
      objName: 'BPJS Data LPK',
      hasCta: 'true',
    },
  },


  {
    path: 'report-admisi/:pageSlug?',
    name: 'RoReportAdmisi',
    component: () => import('@/views/backend/RoReportAdmisi/List.vue'),
    meta: {
      menu: 'RoReportAdmisi',
      pageTitle: 'Report Admisi',
      objName: 'Report Admisi'
    },
  },

  {
    path: 'task-id-harian/:pageSlug?',
    name: 'RoTaskIDHarian',
    component: () => import('@/views/backend/RoTaskIDHarian/List.vue'),
    meta: {
      menu: 'RoTaskIDHarian',
      pageTitle: 'Task ID Harian',
      objName: 'Task ID Harian',
    },
  },

  {
    path: 'belum-dilayani/:pageSlug?',
    name: 'RoBelumDilayani',
    component: () => import('@/views/backend/RoBelumDilayani/List.vue'),
    meta: {
      menu: 'RoBelumDilayani',
      pageTitle: 'Aktifitas Antrean',
      objName: 'Aktifitas Antrean',
    },
  },

  // Data E-Klaim
  {
    path: 'e-klaim-ranap/:pageSlug?',
    name: 'BoEKlaimRanap',
    component: () => import('@/views/backend/BoEKlaimRanap/View.vue'),
    meta: {
      menu: 'BoEKlaimRanap',
      pageTitle: 'Data E-Klaim Ranap',
      objName: 'Data E-Klaim Ranap',
    },
  },
  {
    path: 'e-klaim-ugd/:pageSlug?',
    name: 'BoEKlaimUGD',
    component: () => import('@/views/backend/BoEKlaimUGD/View.vue'),
    meta: {
      menu: 'BoEKlaimUGD',
      pageTitle: 'Data E-Klaim UGD',
      objName: 'Data E-Klaim UGD',
    },
  },
  {
    path: 'e-klaim-poli/:pageSlug?',
    name: 'BoEKlaimPoli',
    component: () => import('@/views/backend/BoEKlaimPoli/View.vue'),
    meta: {
      menu: 'BoEKlaimPoli',
      pageTitle: 'Data E-Klaim Poli',
      objName: 'Data E-Klaim Poli',
    },
  },
  // -- Data E-Klaim
  {
    path: 'jadwal-operasi-crud/:pageSlug?',
    name: 'AppRanapJadwalOperasiCRUD',
    component: () => import('@/views/backend/AppRanapJadwalOperasiCRUD/List.vue'),
    meta: {
      menu: 'AppRanapJadwalOperasiCRUD',
      pageTitle: 'Jadwal Operasi',
      objName: 'Jadwal Operasi',
      hasCta: 'true',
    },
  },
  {
    path: 'jadwal-operasi/:pageSlug?',
    name: 'AppRanapJadwalOperasi',
    component: () => import('@/views/backend/AppRanapJadwalOperasi/List.vue'),
    meta: {
      menu: 'AppRanapJadwalOperasi',
      pageTitle: 'Jadwal Operasi',
      objName: 'Jadwal Operasi',
    },
  },
  {
    path: 'm-ruangan/:pageSlug?',
    name: 'MRuangan',
    component: () => import('@/views/backend/MRuangan/List.vue'),
    meta: {
      menu: 'MRuangan',
      pageTitle: 'Ruangan',
      objName: 'Ruangan',
      hasCta: 'true',
    },
  }, {
    path: 'ugd-rumah-sakit/:pageSlug?',
    name: 'MUGDRS',
    component: () => import('@/views/backend/MUGDRS/List.vue'),
    meta: {
      menu: 'MUGDRS',
      pageTitle: 'Asal Rujukan',
      objName: 'Asal Rujukan',
      hasCta: 'true',
    },
  }, {
    path: 'ugd-puskesmas/:pageSlug?',
    name: 'MUGDPuskesmas',
    component: () => import('@/views/backend/MUGDPuskesmas/List.vue'),
    meta: {
      menu: 'MUGDPuskesmas',
      pageTitle: 'UGD Puskesmas',
      objName: 'UGD Puskesmas',
      hasCta: 'true',
    },
  }, {
    path: 'ketersediaan-kamar/:pageSlug?',
    name: 'BPJSKetersediaanKamar',
    component: () => import('@/views/backend/BPJSKetersediaanKamar/List.vue'),
    meta: {
      menu: 'BPJSKetersediaanKamar',
      pageTitle: 'Ketersediaan Kamar',
      objName: 'Ketersediaan Kamar',
    },
  },

  




  

  // UGD
  {
    path: 'pendaftaran-ugd/:pageSlug?',
    name: 'UGDReservasi',
    component: () => import('@/views/backend/UGDReservasi/List.vue'),
    meta: {
      menu: 'UGDReservasi',
      pageTitle: 'Pendaftaran UGD',
      objName: 'Pendaftaran UGD'
    },
  },
  {
    path: 'triase-ugd/:pageSlug?',
    name: 'UGDTriase',
    component: () => import('@/views/backend/UGDTriase/List.vue'),
    meta: {
      menu: 'UGDTriase',
      pageTitle: 'Triase UGD',
      objName: 'Triase UGD'
    },
  },

  {
    path: 'assesment-ugd-dokter',
    name: 'UGDAssesmentDokter',
    component: () => import('@/views/backend/UGDAssesmentDokter/List.vue'),
    meta: {
      menu: 'UGDAssesmentDokter',
      pageTitle: 'Kajian Dokter UGD',
      objName: 'Kajian Dokter UGD'
    },
  },
  
  {
    path: 'assesment-ugd-perawat',
    name: 'UGDAssesmentPerawat',
    component: () => import('@/views/backend/UGDAssesmentPerawat/List.vue'),
    meta: {
      menu: 'UGDAssesmentPerawat',
      pageTitle: 'Kajian Perawat UGD',
      objName: 'Kajian Perawat UGD'
    },
  },

  {
    path: 'list-assesment/:pageSlug?',
    name: 'UGDAssesment',
    component: () => import('@/views/backend/UGDAssesment/List.vue'),
    meta: {
      menu: 'UGDAssesment',
      pageTitle: 'Form Assesmen',
      objName: 'Form Assesmen',
    },
  },

  {
    path: 'ugd-form-perawat/:pageSlug?/:rmNo?',
    name: 'UGDFormPerawat',
    component: () => import('@/views/backend/UGDFormPerawat/Form.vue'),
    meta: {
      menu: 'UGDFormPerawat',
      pageTitle: 'Form Assesmen Perawat',
      objName: 'Form Assesmen Perawat',
    },
  },

  {
    path: 'ugd-form-dokter/:pageSlug?/:rmNo?',
    name: 'UGDFormDokter',
    component: () => import('@/views/backend/UGDFormDokter/Form.vue'),
    meta: {
      menu: 'UGDFormDokter',
      pageTitle: 'Form Assesmen Dokter',
      objName: 'Form Assesmen Dokter',
    },
  },

  {
    path: 'ugd-tindak-lanjut/:pageSlug?/:typeDokumen?',
    name: 'UGDTindakLanjut',
    component: () => import('@/views/backend/UGDTindakLanjut/List.vue'),
    meta: {
      menu: 'UGDTindakLanjut',
      pageTitle: 'Tindak Lanjut',
      objName: 'Tindak Lanjut',
    },
  },


  // {
  //   path: 'ugd-form-lab/:pageSlug?/:rmNo?',
  //   name: 'UGDFormLab',
  //   component: () => import('@/views/backend/UGDFormLab/Form.vue'),
  //   meta: {
  //     menu: 'UGDFormLab',
  //     pageTitle: 'Form Assesmen Lab',
  //     objName: 'Form Assesmen Lab',
  //   },
  // },
  // {
  //   path: 'ugd-form-radiologi/:pageSlug?/:rmNo?',
  //   name: 'UGDFormRadiologi',
  //   component: () => import('@/views/backend/UGDFormRadiologi/Form.vue'),
  //   meta: {
  //     menu: 'UGDFormRadiologi',
  //     pageTitle: 'Form Assesmen Radiologi',
  //     objName: 'Form Assesmen Radiologi',
  //   },
  // },
  
  {
    path: 'assesment-ugd-lab',
    name: 'UGDAssesmenLab',
    component: () => import('@/views/backend/UGDAssesmenLab/List.vue'),
    meta: {
      menu: 'UGDAssesmenLab',
      pageTitle: 'Assesment UGD Lab',
      objName: 'Assesment UGD Lab'
    },
  },
  {
    path: 'ugd-poct-laboratorium/:pageSlug?',
    name: 'UGDPOCTLab',
    component: () => import('@/views/backend/UGDPOCTLab/List.vue'),
    meta: {
      menu: 'UGDPOCTLab',
      pageTitle: 'UGD POCT Laboratorium',
      objName: 'UGD POCT Laboratorium',
    },
  },


  {
    path: 'assesment-ugd-radiologi',
    name: 'UGDAssesmenRadiologi',
    component: () => import('@/views/backend/UGDAssesmenRadiologi/List.vue'),
    meta: {
      menu: 'UGDAssesmenRadiologi',
      pageTitle: 'Assesment UGD Radiologi',
      objName: 'Assesment UGD Radiologi'
    },
  },

  {
    path: 'ugd-form-lab/:pageSlug?/:rmNo?',
    name: 'UGDFormLab',
    component: () => import('@/views/backend/UGDFormLab/Form.vue'),
    meta: {
      menu: 'UGDFormLab',
      pageTitle: 'Form Assesmen Lab',
      objName: 'Form Assesmen Lab',
    },
  },

  {
    path: 'ugd-form-radiologi/:pageSlug?/:rmNo?',
    name: 'UGDFormRadiologi',
    component: () => import('@/views/backend/UGDFormRadiologi/Form.vue'),
    meta: {
      menu: 'UGDFormRadiologi',
      pageTitle: 'Form Assesmen Radiologi',
      objName: 'Form Assesmen Radiologi',
    },
  },

  {
    path: 'ugd-catatan-asuhan/:pageSlug',
    name: 'UGDCatatanAsuhan',
    component: () => import('@/views/backend/UGDCatatanAsuhan/Form.vue'),
    meta: {
      menu: 'UGDCatatanAsuhan',
      pageTitle: 'Form UGD Catatan Asuhan',
      objName: 'Form UGD Catatan Asuhan',
    },
  },
  {
    path: 'ugd-admisi-antrean',
    name: 'UGDAdmisiAntrean',
    component: () => import('@/views/backend/UGDAdmisiAntrean/List.vue'),
    meta: {
      menu: 'UGDAdmisiAntrean',
      pageTitle: 'UGD Admisi Antrean',
      objName: 'UGD Admisi Antrean',
    },
  },
  {
    path: 'display-antrian-ugd',
    name: 'DisplayAntrianUGD',
    component: () => import('@/views/display/DisplayAntrianUGD.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-admisi-ugd',
    name: 'DisplayAntrianAdmisiUGD',
    component: () => import('@/views/display/DisplayAntrianAdmisiUGD.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'ugd-farmasi/:pageSlug?',
    name: 'UGDFarmasi',
    component: () => import('@/views/backend/UGDFarmasi/List.vue'),
    meta: {
      menu: 'UGDFarmasi',
      pageTitle: 'UGD Farmasi',
      objName: 'UGD Farmasi',
    },
  },
  {
    path: 'rekonsiliasi-obat/:pageSlug?',
    name: 'UGDRekonsiliasi',
    component: () => import('@/views/backend/UGDRekonsiliasi/List.vue'),
    meta: {
      menu: 'UGDRekonsiliasi',
      pageTitle: 'UGD Rekonsiliasi',
      objName: 'UGD Rekonsiliasi',
    },
  },
  {
    path: 'ugd-rekam-medis/:pageSlug?',
    name: 'UGDRekamMedis',
    component: () => import('@/views/backend/UGDRekamMedis/List.vue'),
    meta: {
      menu: 'UGDRekamMedis',
      pageTitle: 'UGD Rekam Medis',
      objName: 'UGD Rekam Medis',
    },
  }, 
  {
    path: 'ugd-upla/:pageSlug?',
    name: 'UGDUPLA',
    component: () => import('@/views/backend/UGDUPLA/List.vue'),
    meta: {
      menu: 'UGDUPLA',
      pageTitle: 'UGD UPLA',
      objName: 'UGD UPLA',
    },
  },
  {
    path: 'ugd-rujukan-masuk/:pageSlug?',
    name: 'UGDRujukanMasuk',
    component: () => import('@/views/backend/UGDRujukanMasuk/List.vue'),
    meta: {
      menu: 'UGDRujukanMasuk',
      pageTitle: 'UGD Rujukan Masuk',
      objName: 'UGD Rujukan Masuk',
    },
  },
  {
    path: 'ugd-rujukan-keluar/:pageSlug?',
    name: 'UGDRujukanKeluar',
    component: () => import('@/views/backend/UGDRujukanKeluar/List.vue'),
    meta: {
      menu: 'UGDRujukanKeluar',
      pageTitle: 'UGD Rujukan Keluar',
      objName: 'UGD Rujukan Keluar',
    },
  },
  {
    path: 'ugd-penerbitan-surat/:pageSlug?',
    name: 'UGDPenerbitanSurat',
    component: () => import('@/views/backend/UGDPenerbitanSurat/List.vue'),
    meta: {
      menu: 'UGDPenerbitanSurat',
      pageTitle: 'UGD Penerbitan Surat',
      objName: 'UGD Penerbitan Surat',
    },
  },
  {
    path: 'ugd-surat-emergency/:pageSlug?',
    name: 'UGDSuratEmergency',
    component: () => import('@/views/backend/UGDSuratEmergency/List.vue'),
    meta: {
      menu: 'UGDSuratEmergency',
      pageTitle: 'UGD Surat Emergency',
      objName: 'UGD Surat Emergency',
    },
  },

  {
    path: 'ugd-report-admisi',
    name: 'UGDReportAdmisi',
    component: () => import('@/views/backend/UGDReportAdmisi/List.vue'),
    meta: {
      menu: 'UGDReportAdmisi',
      pageTitle: 'UGD Report Admisi',
      objName: 'UGD Report Admisi'
    },
  },
  
  {
    path: 'ugd-hais/:pageSlug?',
    name: 'UGDHais',
    component: () => import('@/views/backend/UGDHais/List.vue'),
    meta: {
      menu: 'UGDHais',
      pageTitle: 'UGD Hais',
      objName: 'UGD Hais'
    },
  },
  
  {
    path: 'ugd-pasien-ranap/:pageSlug?',
    name: 'UGDPasienRanap',
    component: () => import('@/views/backend/UGDPasienRanap/List.vue'),
    meta: {
      menu: 'UGDPasienRanap',
      pageTitle: 'Pasien Rawat Inap',
      objName: 'Pasien Rawat Inap'
    },
  },
  
  {
    path: 'report-fisioterapi',
    name: 'ReportFisioterapi',
    component: () => import('@/views/backend/ReportFisioterapi/List.vue'),
    meta: {
      menu: 'ReportFisioterapi',
      pageTitle: 'Report Fisioterapi',
      objName: 'Report Fisioterapi'
    },
  },
  
  {
    path: 'report-farmasi',
    name: 'ReportFarmasi',
    component: () => import('@/views/backend/ReportFarmasi/List.vue'),
    meta: {
      menu: 'ReportFarmasi',
      pageTitle: 'Report Farmasi',
      objName: 'Report Farmasi'
    },
  },  
  {
    path: 'report-laboratorium',
    name: 'ReportLab',
    component: () => import('@/views/backend/ReportLab/List.vue'),
    meta: {
      menu: 'ReportLab',
      pageTitle: 'Report Laboratorium',
      objName: 'Report Laboratorium'
    },
  },  
  {
    path: 'report-radiologi',
    name: 'ReportRadiologi',
    component: () => import('@/views/backend/ReportRadiologi/List.vue'),
    meta: {
      menu: 'ReportRadiologi',
      pageTitle: 'Report Radiologi',
      objName: 'Report Radiologi'
    },
  },
  {
    path: 'ranap-bangsal/:pageSlug?',
    name: 'MRanapBangsal',
    component: () => import('@/views/backend/MRanapBangsal/List.vue'),
    meta: {
      
      menu: 'MRanapBangsal',
      pageTitle: 'Ranap Bangsal',
      objName: 'Ranap Bangsal',
      hasCta: 'true',
    },
  },
  {
    path: 'ranap-kelas/:pageSlug?',
    name: 'MRanapKelas',
    component: () => import('@/views/backend/MRanapKelas/List.vue'),
    meta: {
      menu: 'MRanapKelas',
      pageTitle: 'Ranap Kelas',
      objName: 'Ranap Kelas',
      hasCta: 'true',
    },
  },
  {
    path: 'ranap-kamar/:pageSlug?',
    name: 'MRanapKamar',
    component: () => import('@/views/backend/MRanapKamar/List.vue'),
    meta: {
      menu: 'MRanapKamar',
      pageTitle: 'Ranap Kamar',
      objName: 'Ranap Kamar',
      hasCta: 'true',
    },
  },
  {
    path: 'ranap-bed/:pageSlug?',
    name: 'MRanapBed',
    component: () => import('@/views/backend/MRanapBed/List.vue'),
    meta: {
      menu: 'MRanapBed',
      pageTitle: 'Ranap Bed',
      objName: 'Ranap Bed',
      hasCta: 'true',
    },
  },
  {
    path: 'report-admisi-rajal/:pageSlug?',
    name: 'ReportAdmisiRajal',
    component: () => import('@/views/backend/ReportAdmisiRajal/List.vue'),
    meta: {
        menu: 'ReportAdmisiRajal',
        pageTitle: 'Report Admisi Rajal',
        objName: 'Report Admisi Rajal'
    },
  },
  {
    path: 'report-admisi-ugd/:pageSlug?',
    name: 'ReportAdmisiUGD',
    component: () => import('@/views/backend/ReportAdmisiUGD/List.vue'),
    meta: {
      menu: 'ReportAdmisiUGD',
      pageTitle: 'Report Admisi UGD',
      objName: 'Report Admisi UGD'
    },
  },
  // UGD

  {
    path: 'kirim-pesan-massal/:pageSlug?',
    name: 'WABlasting',
    component: () => import('@/views/backend/WABlasting/List.vue'),
    meta: {
      menu: 'WABlasting',
      pageTitle: 'Kirim Pesan Massal',
      objName: 'Kirim Pesan Massal'
    },
  },


  // RANAP
  {
    path: 'pendaftaran-ranap/:pageSlug?',
    name: 'RanapReservasi',
    component: () => import('@/views/backend/RanapReservasi/List.vue'),
    meta: {
      menu: 'RanapReservasi',
      pageTitle: 'Pendaftaran Rawat Inap',
      objName: 'Pendaftaran Rawat Inap'
    },
  },
  {
    path: 'pasien-rujukan/:pageSlug?',
    name: 'RanapRujukan',
    component: () => import('@/views/backend/RanapRujukan/List.vue'),
    meta: {
      menu: 'RanapRujukan',
      pageTitle: 'Rujukan',
      objName: 'Rujukan'
    },
  },
  {
    path: 'ketersediaan-kamar-ranap/:pageSlug?',
    name: 'RanapKetersediaanKamar',
    component: () => import('@/views/backend/RanapKetersediaanKamar/List.vue'),
    meta: {
      menu: 'RanapKetersediaanKamar',
      pageTitle: 'Ketersediaan Kamar',
      objName: 'Ketersediaan Kamar'
    },
  },
  {
    path: 'pasien-bangsal/:pageSlug?',
    name: 'RanapPasienBangsal',
    component: () => import('@/views/backend/RanapPasienBangsal/List.vue'),
    meta: {
      menu: 'RanapPasienBangsal',
      pageTitle: 'Pasien Bangsal',
      objName: 'Pasien Bangsal'
    },
  },
  {
    path: 'pasien-perawatan/:pageSlug?',
    name: 'RanapPasienPerawatan',
    component: () => import('@/views/backend/RanapPasienPerawatan/List.vue'),
    meta: {
      menu: 'RanapPasienPerawatan',
      pageTitle: 'Pasien Perawatan',
      objName: 'Pasien Perawatan'
    },
  },

  {
    path: 'pasien-perawatan-dpjp/:pageSlug?',
    name: 'RanapPasienPerawatanDPJP',
    component: () => import('@/views/backend/RanapPasienPerawatanDPJP/List.vue'),
    meta: {
      menu: 'RanapPasienPerawatanDPJP',
      pageTitle: 'Pasien Perawatan DPJP',
      objName: 'Pasien Perawatan DPJP'
    },
  },
  {
    path: 'pasien-selesai/:pageSlug?',
    name: 'RanapPasienSelesai',
    component: () => import('@/views/backend/RanapPasienSelesai/List.vue'),
    meta: {
      menu: 'RanapPasienSelesai',
      pageTitle: 'Pasien Selesai',
      objName: 'Pasien Selesai'
    },
  },

  {
    path: 'pasien-perawatan-gizi/:pageSlug?',
    name: 'RanapPasienPerawatanGizi',
    component: () => import('@/views/backend/RanapPasienPerawatanGizi/List.vue'),
    meta: {
      menu: 'RanapPasienPerawatanGizi',
      pageTitle: 'Pasien Perawatan Gizi',
      objName: 'Pasien Perawatan Gizi'
    },
  },
  
  {
    path: 'kajian-awal-perawat-umum/:pageSlug?/:rmNo?',
    name: 'RanapKAPerawatUmum',
    component: () => import('@/views/backend/RanapKAPerawatUmum/Form.vue'),
    meta: {
      menu: 'RanapKAPerawatUmum',
      pageTitle: 'Kajian Perawat Umum',
      objName: 'Kajian Perawat Umum'
    },
  },
  {
    path: 'kajian-awal-perawat-anak/:pageSlug?/:rmNo?',
    name: 'RanapKAPerawatAnak',
    component: () => import('@/views/backend/RanapKAPerawatAnak/Form.vue'),
    meta: {
      menu: 'RanapKAPerawatAnak',
      pageTitle: 'Kajian Perawat Anak',
      objName: 'Kajian Perawat Anak'
    },
  },
  {
    path: 'kajian-awal-perawat-obsgyn/:pageSlug?/:rmNo?',
    name: 'RanapKAPerawatObsgyn',
    component: () => import('@/views/backend/RanapKAPerawatObsgyn/Form.vue'),
    meta: {
      menu: 'RanapKAPerawatObsgyn',
      pageTitle: 'Kajian Perawat Obsgyn',
      objName: 'Kajian Perawat Obsgyn'
    },
  },
  {
    path: 'kajian-awal-perawat-icu/:pageSlug?/:rmNo?',
    name: 'RanapKAPerawatICU',
    component: () => import('@/views/backend/RanapKAPerawatICU/Form.vue'),
    meta: {
      menu: 'RanapKAPerawatICU',
      pageTitle: 'Kajian Perawat ICU',
      objName: 'Kajian Perawat ICU'
    },
  },
  {
    path: 'kajian-awal-perawat-nicu/:pageSlug?/:rmNo?',
    name: 'RanapKAPerawatNICU',
    component: () => import('@/views/backend/RanapKAPerawatNICU/Form.vue'),
    meta: {
      menu: 'RanapKAPerawatNICU',
      pageTitle: 'Kajian Perawat NICU',
      objName: 'Kajian Perawat NICU'
    },
  },
  {
    path: 'kajian-awal-perawat-picu/:pageSlug?/:rmNo?',
    name: 'RanapKAPerawatPICU',
    component: () => import('@/views/backend/RanapKAPerawatPICU/Form.vue'),
    meta: {
      menu: 'RanapKAPerawatPICU',
      pageTitle: 'Kajian Perawat PICU',
      objName: 'Kajian Perawat PICU'
    },
  },
  {
    path: 'kajian-awal-bbl/:pageSlug?/:rmNo?',
    name: 'RanapKAPerawatBBL',
    component: () => import('@/views/backend/RanapKAPerawatBBL/Form.vue'),
    meta: {
      menu: 'RanapKAPerawatBBL',
      pageTitle: 'Kajian Perawat BBL',
      objName: 'Kajian Perawat BBL'
    },
  },


  {
    path: 'kajian-awal-medis/:pageSlug?/:rmNo?',
    name: 'RanapKAMedis',
    component: () => import('@/views/backend/RanapKAMedis/Form.vue'),
    meta: {
      menu: 'RanapKAMedis',
      pageTitle: 'Kajian Medis',
      objName: 'Kajian Medis'
    },
  }, {
    path: 'kajian-awal-medis-bbl/:pageSlug?/:rmNo?',
    name: 'RanapKAMedisBBL',
    component: () => import('@/views/backend/RanapKAMedisBBL/Form.vue'),
    meta: {
      menu: 'RanapKAMedisBBL',
      pageTitle: 'Kajian Medis BBL',
      objName: 'Kajian Medis BBL'
    },
  }, {
    path: 'kajian-awal-gizi/:pageSlug?/:rmNo?',
    name: 'RanapKAGizi',
    component: () => import('@/views/backend/RanapKAGizi/Form.vue'),
    meta: {
      menu: 'RanapKAGizi',
      pageTitle: 'Kajian Medis Gizi',
      objName: 'Kajian Medis Gizi'
    },
  },
  
  // RANAP

  // RANAP PERAWATAN
  {
    path: 'kriteria-discharge-planning/:pageSlug?',
    name: 'DischargePlanningCriteria',
    component: () => import('@/views/backend/RanapDischargePlanningCriteria/Form.vue'),
    meta: {
      menu: 'DischargePlanningCriteria',
      pageTitle: 'Discharge Planning',
      objName: 'Discharge Planning'
    },
  },

  {
    path: 'monitoring/:pageSlug?',
    name: 'RanapMonitoring',
    component: () => import('@/views/backend/RanapMonitoring/View.vue'),
    meta: {
      menu: 'RanapMonitoring',
      pageTitle: 'Rawat Inap Monitoring',
      objName: 'Rawat Inap Monitoring',
      isDetailRanap: true
    },
  },

  {
    path: 'cppt/:pageSlug?',
    name: 'RanapCPPT',
    component: () => import('@/views/backend/RanapCPPT/List.vue'),
    meta: {
      menu: 'RanapCPPT',
      pageTitle: 'Rawat Inap CPPT',
      objName: 'Rawat Inap CPPT',
      isDetailRanap: true
    },
  },

  {
    path: 'cppt-soap/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTSOAP',
    component: () => import('@/views/backend/RanapCPPTSOAP/Form.vue'),
    meta: {
      menu: 'RanapCPPTSOAP',
      pageTitle: 'Rawat Inap CPPT SOAP',
      objName: 'Rawat Inap CPPT SOAP',
      isDetailRanap: true
    },
  },
  {
    path: 'cppt-sbar/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTSBAR',
    component: () => import('@/views/backend/RanapCPPTSBAR/Form.vue'),
    meta: {
      menu: 'RanapCPPTSBAR',
      pageTitle: 'Rawat Inap CPPT SBAR',
      objName: 'Rawat Inap CPPT SBAR',
      isDetailRanap: true
    },
  },
  {
    path: 'cppt-dokter/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTDokter',
    component: () => import('@/views/backend/RanapCPPTDokter/Form.vue'),
    meta: {
      menu: 'RanapCPPTDokter',
      pageTitle: 'Rawat Inap CPPT Dokter',
      objName: 'Rawat Inap CPPT Dokter',
      isDetailRanap: true
    },
  },
  {
    path: 'cppt-ppa-radio/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTPPARADIO',
    component: () => import('@/views/backend/RanapCPPTPPARADIO/Form.vue'),
    meta: {
      menu: 'RanapCPPTPPARADIO',
      pageTitle: 'Rawat Inap CPPT PPA Radiologi',
      objName: 'Rawat Inap CPPT PPA Radiologi'
    },
  },
  {
    path: 'cppt-ppa-lab/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTPPALAB',
    component: () => import('@/views/backend/RanapCPPTPPALAB/Form.vue'),
    meta: {
      menu: 'RanapCPPTPPALAB',
      pageTitle: 'Rawat Inap CPPT PPA Lab',
      objName: 'Rawat Inap CPPT PPA Lab'
    },
  },
  {
    path: 'cppt-dietisien/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTDietisien',
    component: () => import('@/views/backend/RanapCPPTDietisien/Form.vue'),
    meta: {
      menu: 'RanapCPPTDietisien',
      pageTitle: 'Rawat Inap CPPT Dietisien',
      objName: 'Rawat Inap CPPT Dietisien'
    },
  },
  {
    path: 'cppt-fisio/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTPPAFisio',
    component: () => import('@/views/backend/RanapCPPTPPAFisio/Form.vue'),
    meta: {
      menu: 'RanapCPPTPPAFisio',
      pageTitle: 'Rawat Inap CPPT Fisioterapi',
      objName: 'Rawat Inap CPPT Fisioterapi'
    },
  },
  {
    path: 'cppt-farmasi/:pageSlug?/:cpptNo?',
    name: 'RanapCPPTPPAFarmasi',
    component: () => import('@/views/backend/RanapCPPTPPAFarmasi/Form.vue'),
    meta: {
      menu: 'RanapCPPTPPAFarmasi',
      pageTitle: 'Rawat Inap CPPT Farmasi',
      objName: 'Rawat Inap CPPT Farmasi'
    },
  },
  {
    path: 'cppt-list',
    name: 'RanapCPPTList',
    component: () => import('@/views/backend/RanapCPPTList/List.vue'),
    meta: {
      menu: 'RanapCPPTList',
      pageTitle: 'Rawat Inap CPPT List',
      objName: 'Rawat Inap CPPT List'
    },
  },

  {
    path: 'ranap-operasi-dokter-anestesi/:pageSlug?',
    name: 'RanapOperasiDokterAnestesi',
    component: () => import('@/views/backend/RanapOperasiDokterAnestesi/List.vue'),
    meta: {
      menu: 'RanapOperasiDokterAnestesi',
      pageTitle: 'Rawat Inap Operasi',
      objName: 'Rawat Inap Operasi'
    },
  },

  {
    path: 'ranap-operasi-perawat-bedah/:pageSlug?',
    name: 'RanapOperasiPerawatBedah',
    component: () => import('@/views/backend/RanapOperasiPerawatBedah/List.vue'),
    meta: {
      menu: 'RanapOperasiPerawatBedah',
      pageTitle: 'Rawat Inap Bedah',
      objName: 'Rawat Inap Bedah'
    },
  },
  
  {
    path: 'ranap-operasi-dokter-operator/:pageSlug?',
    name: 'RanapOperasiDokterOperator',
    component: () => import('@/views/backend/RanapOperasiDokterOperator/List.vue'),
    meta: {
      menu: 'RanapOperasiDokterOperator',
      pageTitle: 'Rawat Inap Operator',
      objName: 'Rawat Inap Operator'
    },
  },

  {
    path: 'ranap-operasi-ppjp/:pageSlug?',
    name: 'RanapOperasiPPJP',
    component: () => import('@/views/backend/RanapOperasiPPJP/List.vue'),
    meta: {
      menu: 'RanapOperasiPPJP',
      pageTitle: 'Rawat Inap PPJP',
      objName: 'Rawat Inap PPJP'
    },
  },

  
  // RANAP PERAWATAN

  // RANAP MPP
  {
    path: 'mpp-ranap/:pageSlug?',
    name: 'RanapMPP',
    component: () => import('@/views/backend/RanapMPP/List.vue'),
    meta: {
      menu: 'RanapMPP',
      pageTitle: 'Daftar Pasien MPP',
      objName: 'MPP Ranap'
    },
  },

  // RANAP Laundry
  {
    path: 'laundry-ranap/:pageSlug?',
    name: 'RanapLaundry',
    component: () => import('@/views/backend/RanapLaundry/List.vue'),
    meta: {
      menu: 'RanapLaundry',
      pageTitle: 'Daftar Laundry',
      objName: 'Laundry Ranap'
    },
  },

  // RANAP DISCHARGE PLANNING
  {
    path: 'discharge-planning-dietisien/:pageSlug?',
    name: 'RanapDPDietisien',
    component: () => import('@/views/backend/RanapDPDietisien/List.vue'),
    meta: {
      menu: 'RanapDPDietisien',
      pageTitle: 'Daftar Pasien Discharge Planning',
      objName: 'Discharge Planning'
    },
  },
  {
    path: 'discharge-planning-farmasi/:pageSlug?',
    name: 'RanapDPFarmasi',
    component: () => import('@/views/backend/RanapDPFarmasi/List.vue'),
    meta: {
      menu: 'RanapDPFarmasi',
      pageTitle: 'Daftar Pasien Discharge Planning',
      objName: 'Discharge Planning'
    },
  },
  {
    path: 'discharge-planning-perawat/:pageSlug?',
    name: 'RanapDPPerawat',
    component: () => import('@/views/backend/RanapDPPerawat/List.vue'),
    meta: {
      menu: 'RanapDPPerawat',
      pageTitle: 'Daftar Pasien Discharge Planning',
      objName: 'Discharge Planning',
      isDetailRanap: true
    },
  },
  {
    path: 'discharge-planning-fisioterapi/:pageSlug?',
    name: 'RanapDPFisioterapi',
    component: () => import('@/views/backend/RanapDPFisioterapi/List.vue'),
    meta: {
      menu: 'RanapDPFisioterapi',
      pageTitle: 'Daftar Pasien Discharge Planning',
      objName: 'Discharge Planning',      
    },
  },
  {
    path: 'ranap-pasien-operasi/:pageSlug?',
    name: 'RanapPasienOperasi',
    component: () => import('@/views/backend/RanapPasienOperasi/List.vue'),
    meta: {
      menu: 'RanapPasienOperasi',
      pageTitle: 'Ranap Tindakan Operasi',
      objName: 'Ranap Tindakan Operasi',
      isDetailRanap: true
    },
  },

  // RANAP EDUKASI TERINTEGRASI
  {
    path: 'edukasi-integrasi-dietisien/:pageSlug?',
    name: 'RanapETDietisien',
    component: () => import('@/views/backend/RanapETDietisien/List.vue'),
    meta: {
      menu: 'RanapETDietisien',
      pageTitle: 'Daftar Pasien Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi'
    },
  },
  {
    path: 'edukasi-integrasi-farmasi/:pageSlug?',
    name: 'RanapETFarmasi',
    component: () => import('@/views/backend/RanapETFarmasi/List.vue'),
    meta: {
      menu: 'RanapETFarmasi',
      pageTitle: 'Daftar Pasien Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi'
    },
  },
  {
    path: 'edukasi-integrasi-mpp/:pageSlug?',
    name: 'RanapETMPP',
    component: () => import('@/views/backend/RanapETMPP/List.vue'),
    meta: {
      menu: 'RanapETMPP',
      pageTitle: 'Daftar Pasien Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi'
    },
  },
  {
    path: 'edukasi-integrasi-perawat/:pageSlug?',
    name: 'RanapETPerawat',
    component: () => import('@/views/backend/RanapETPerawat/List.vue'),
    meta: {
      menu: 'RanapETPerawat',
      pageTitle: 'Daftar Pasien Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi',
      isDetailRanap: true
    },
  },
  {
    path: 'edukasi-integrasi/:pageSlug?',
    name: 'RanapETPerawat',
    component: () => import('@/views/backend/RanapETPerawat/List.vue'),
    meta: {
      menu: 'RanapETPerawat',
      pageTitle: 'Daftar Pasien Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi',
      isDetailRanap: true
    },
  },
  {
    path: 'edukasi-integrasi-fisioterapi/:pageSlug?',
    name: 'RanapETFisioterapi',
    component: () => import('@/views/backend/RanapETFisioterapi/List.vue'),
    meta: {
      menu: 'RanapETFisioterapi',
      pageTitle: 'Daftar Pasien Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi',      
    },
  },
  {
    path: 'edukasi-integrasi-dokter/:pageSlug?',
    name: 'RanapETDokter',
    component: () => import('@/views/backend/RanapETDokter/List.vue'),
    meta: {
      menu: 'RanapETDokter',
      pageTitle: 'Daftar Pasien Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi', 
      isDetailRanap: true
    },
  },

  {
    path: 'pasien-selesai-farmasi/:pageSlug?',
    name: 'RanapPasienSelesaiFarmasi',
    component: () => import('@/views/backend/RanapPasienSelesaiFarmasi/List.vue'),
    meta: {
      menu: 'RanapPasienSelesaiFarmasi',
      pageTitle: 'Daftar Pasien Selesai',
      objName: 'Daftar Pasien Selesai'
    },
  },
  {
    path: 'ranap-meso/:pageSlug?',
    name: 'RanapMESO',
    component: () => import('@/views/backend/RanapMESO/List.vue'),
    meta: {
      menu: 'RanapMESO',
      pageTitle: 'Daftar Pasien',
      objName: 'Daftar Pasien'
    },
  },

  {
    path: 'pemberian-makan-dietisien/:pageSlug?',
    name: 'RanapPMDietisien',
    component: () => import('@/views/backend/RanapPMDietisien/List.vue'),
    meta: {
      menu: 'RanapPMDietisien',
      pageTitle: 'Daftar Pasien',
      objName: 'Pemberian Makan'
    },
  },

  {
    path: 'ranap-pasien-operasi/:pageSlug?',
    name: 'RanapPasienOperasi',
    component: () => import('@/views/backend/RanapPasienOperasi/List.vue'),
    meta: {
      menu: 'RanapPasienOperasi',
      pageTitle: 'Ranap Tindakan Operasi',
      objName: 'Ranap Tindakan Operasi',
      isDetailRanap: true
    },
  },

  {
    path: 'ranap-pasien-tindak-lanjut-lab/:pageSlug?/:labNo?',
    name: 'RanapPasienTindakanLaboratorium',
    component: () => import('@/views/backend/RanapPasienTindakanLaboratorium/List.vue'),
    meta: {
      menu: 'RanapPasienTindakanLaboratorium',
      pageTitle: 'Ranap Pasien Tindak Lanjut Lab',
      objName: 'Ranap Pasien Tindak Lanjut Lab',
      isDetailRanap: true
    },
  },
  {
    path: 'ranap-laboratorium/:pageSlug?',
    name: 'RanapTindakanLaboratorium',
    component: () => import('@/views/backend/RanapTindakanLaboratorium/List.vue'),
    meta: {
      menu: 'RanapTindakanLaboratorium',
      pageTitle: 'Ranap Tindakan Laboratorium',
      objName: 'Ranap Tindakan Laboratorium',
    },
  },
  {
    path: 'ranap-poct-laboratorium/:pageSlug?',
    name: 'RanapPOCTLab',
    component: () => import('@/views/backend/RanapPOCTLab/List.vue'),
    meta: {
      menu: 'RanapPOCTLab',
      pageTitle: 'Ranap POCT Laboratorium',
      objName: 'Ranap POCT Laboratorium',
    },
  },

  {
    path: 'ranap-pasien-tindak-lanjut-radiologi/:pageSlug?/:radiologiNo?',
    name: 'RanapPasienTindakanRadiologi',
    component: () => import('@/views/backend/RanapPasienTindakanRadiologi/List.vue'),
    meta: {
      menu: 'RanapPasienTindakanRadiologi',
      pageTitle: 'Ranap Pasien Tindak Lanjut Radiologi',
      objName: 'Ranap Pasien Tindak Lanjut Radiologi',
      isDetailRanap: true
    },
  },
  {
    path: 'ranap-radiologi/:pageSlug?',
    name: 'RanapTindakanRadiologi',
    component: () => import('@/views/backend/RanapTindakanRadiologi/List.vue'),
    meta: {
      menu: 'RanapTindakanRadiologi',
      pageTitle: 'Ranap Tindakan Radiologi',
      objName: 'Ranap Tindakan Radiologi',
    },
  },


  {
    path: 'ranap-pasien-tindak-lanjut-fisioterapi/:pageSlug?/:fisioNo?',
    name: 'RanapPasienTindakanFisioterapi',
    component: () => import('@/views/backend/RanapPasienTindakanFisioterapi/List.vue'),
    meta: {
      menu: 'RanapPasienTindakanFisioterapi',
      pageTitle: 'Ranap Pasien Tindak Lanjut Fisioterapi',
      objName: 'Ranap Pasien Tindak Lanjut Fisioterapi',
      isDetailRanap: true
    },
  },
  {
    path: 'ranap-fisioterapi/:pageSlug?',
    name: 'RanapTindakanFisioterapi',
    component: () => import('@/views/backend/RanapTindakanFisioterapi/List.vue'),
    meta: {
      menu: 'RanapTindakanFisioterapi',
      pageTitle: 'Ranap Tindakan Fisioterapi',
      objName: 'Ranap Tindakan Fisioterapi',
    },
  },
  
  {
    path: 'ranap-pasien-tindakan-icd-9/:pageSlug?',
    name: 'RanapPasienTindakanICD9',
    component: () => import('@/views/backend/RanapPasienTindakanICD9/List.vue'),
    meta: {
      menu: 'RanapPasienTindakanICD9',
      pageTitle: 'Ranap Pasien Tindakan Dokter',
      objName: 'Ranap Pasien Tindakan Dokter',
      isDetailRanap: true
    },
  },

  {
    path: 'ranap-pasien-asuhan-keperawatan/:pageSlug?/:askepNo?',
    name: 'RanapPasienAsuhanKeperawatan',
    component: () => import('@/views/backend/RanapPasienAsuhanKeperawatan/List.vue'),
    meta: {
      menu: 'RanapPasienAsuhanKeperawatan',
      pageTitle: 'Ranap Pasien Asuhan Keperawatan',
      objName: 'Ranap Pasien Asuhan Keperawatan',
      isDetailRanap: true
    },
  },

  {
    path: 'ranap-pasien-permintaan-resep/:pageSlug?/:resepNo?',
    name: 'RanapPasienPermintaanResep',
    component: () => import('@/views/backend/RanapPasienPermintaanResep/List.vue'),
    meta: {
      menu: 'RanapPasienPermintaanResep',
      pageTitle: 'Ranap Pasien Permintaan Resep',
      objName: 'Ranap Pasien Permintaan Resep',
      isDetailRanap: true
    },
  },

  {
    path: 'serah-terima-pasien/:pageSlug?/:form?/:id?',
    name: 'RanapSerahTerimaPasien',
    component: () => import('@/views/backend/RanapSerahTerimaPasien/List.vue'),
    meta: {
      menu: 'RanapSerahTerimaPasien',
      pageTitle: 'Serah Terima Pasien',
      objName: 'Serah Terima Pasien',
      isDetailRanap: true
    },
  },

  {
    path: 'ranap-farmasi/:pageSlug?',
    name: 'RanapFarmasi',
    component: () => import('@/views/backend/RanapFarmasi/List.vue'),
    meta: {
      menu: 'RanapFarmasi',
      pageTitle: 'Ranap Farmasi',
      objName: 'Ranap Farmasi',
    },
  },

  {
    path: 'ranap-farmasi-resep-pulang/:pageSlug?',
    name: 'RanapFarmasiResepPulang',
    component: () => import('@/views/backend/RanapFarmasiResepPulang/List.vue'),
    meta: {
      menu: 'RanapFarmasiResepPulang',
      pageTitle: 'Ranap Farmasi Resep Pulang',
      objName: 'Ranap Farmasi Resep Pulang',
    },
  },

  {
    path: 'ranap-farmasi-return-obat/:pageSlug?',
    name: 'RanapReturnObatFarmasi',
    component: () => import('@/views/backend/RanapReturnObatFarmasi/List.vue'),
    meta: {
      menu: 'RanapReturnObatFarmasi',
      pageTitle: 'Ranap Return Farmasi',
      objName: 'Ranap Return Farmasi',
    },
  },

  {
    path: 'ranap-konsultasi/:pageSlug?',
    name: 'RanapKonsultasi',
    component: () => import('@/views/backend/RanapKonsultasi/List.vue'),
    meta: {
      menu: 'RanapKonsultasi',
      pageTitle: 'Ranap Konsultasi',
      objName: 'Ranap Konsultasi',
    },
  },
  {
    path: 'ranap-raber/:pageSlug?',
    name: 'RanapRaber',
    component: () => import('@/views/backend/RanapRaber/List.vue'),
    meta: {
      menu: 'RanapRaber',
      pageTitle: 'Ranap Rawat Bersama',
      objName: 'Ranap Rawat Bersama',
    },
  },
  {
    path: 'ranap-alih-rawat/:pageSlug?',
    name: 'RanapAlihRawat',
    component: () => import('@/views/backend/RanapAlihRawat/List.vue'),
    meta: {
      menu: 'RanapAlihRawat',
      pageTitle: 'Ranap Alih Rawat',
      objName: 'Ranap Alih Rawat',
    },
  },
  {
    path: 'ranap-konsultasi-dietisien/:pageSlug?',
    name: 'RanapKonsulDietisien',
    component: () => import('@/views/backend/RanapKonsulDietisien/List.vue'),
    meta: {
      menu: 'RanapKonsulDietisien',
      pageTitle: 'Ranap Konsultasi Dietisien',
      objName: 'Ranap Konsultasi Dietisien',
    },
  },

  
  {
    path: 'ranap-kajian-nyeri/:pageSlug?/:kajianNo?',
    name: 'RanapPasienKajianNyeri',
    component: () => import('@/views/backend/RanapPasienKajianNyeri/List.vue'),
    meta: {
      menu: 'RanapPasienKajianNyeri',
      pageTitle: 'Ranap Pasien Kajian Nyeri',
      objName: 'Ranap Pasien Kajian Nyeri',
      isDetailRanap: true
    },
  },

  {
    path: 'ranap-kajian-resiko-jatuh/:pageSlug?/:kajianNo?',
    name: 'RanapPasienKajianResikoJatuh',
    component: () => import('@/views/backend/RanapPasienKajianResikoJatuh/List.vue'),
    meta: {
      menu: 'RanapPasienKajianResikoJatuh',
      pageTitle: 'Ranap Pasien Resiko Jatuh',
      objName: 'Ranap Pasien Resiko Jatuh',
      isDetailRanap: true
    },
  },

  {
    path: 'ranap-kajian-terminal/:pageSlug?/:kajianNo?',
    name: 'RanapPasienKajianTerminal',
    component: () => import('@/views/backend/RanapPasienKajianTerminal/List.vue'),
    meta: {
      menu: 'RanapPasienKajianTerminal',
      pageTitle: 'Ranap Pasien Terminal',
      objName: 'Ranap Pasien Terminal',
      isDetailRanap: true
    },
  },
  {
    path: 'ranap-kajian-tbc/:pageSlug?/:kajianNo?',
    name: 'RanapPasienKajianTBC',
    component: () => import('@/views/backend/RanapPasienKajianTBC/List.vue'),
    meta: {
      menu: 'RanapPasienKajianTBC',
      pageTitle: 'Ranap Pasien TBC',
      objName: 'Ranap Pasien TBC',
      isDetailRanap: true
    }
  },
  {
    path: 'ranap-pemberian-makan/:pageSlug?',
    name: 'RanapPemberianMakan',
    component: () => import('@/views/backend/RanapPemberianMakan/List.vue'),
    meta: {
      menu: 'RanapPemberianMakan',
      pageTitle: 'Pemberian Makan',
      objName: 'Pemberian Makan',
      isDetailRanap: true
    }
  },
  {
    path: 'ranap-catatan-persalinan/:pageSlug?',
    name: 'RanapCatatanPersalinan',
    component: () => import('@/views/backend/RanapKajianPersalinan/CatatanPersalinan.vue'),
    meta: {
      menu: 'RanapCatatanPersalinan',
      pageTitle: 'CatatanPersalinan',
      objName: 'CatatanPersalinan',
    }
  },
  {
    path: 'ranap-kajian-mandiri-sc/:pageSlug?',
    name: 'RanapKajianMandiriSC',
    component: () => import('@/views/backend/RanapKajianPersalinan/KajianMandiriSC.vue'),
    meta: {
      menu: 'RanapKajianMandiriSC',
      pageTitle: 'Kajian Mandiri',
      objName: 'Kajian Mandiri',
    }
  },
  {
    path: 'form-laporan-nifas/:pageSlug?',
    name: 'RanapLaporanNifas',
    component: () => import('@/views/backend/RanapKajianPersalinan/LaporanNifas.vue'),
    meta: {
      menu: 'RanapLaporanNifas',
      pageTitle: 'Laporan Nifas',
      objName: 'Laporan Nifas',
    }
  },
  {
    path: 'form-laporan-persalinan/:pageSlug?',
    name: 'RanapLaporanPersalinan',
    component: () => import('@/views/backend/RanapKajianPersalinan/LaporanPersalinan.vue'),
    meta: {
      menu: 'RanapLaporanPersalinan',
      pageTitle: 'Laporan Persalinan',
      objName: 'Laporan Persalinan',
    }
  },
  {
    path: 'ranap-kajian-restrain/:pageSlug?/:kajianNo?',
    name: 'RanapPasienKajianRestrain',
    component: () => import('@/views/backend/RanapPasienKajianRestrain/List.vue'),
    meta: {
      menu: 'RanapPasienKajianRestrain',
      pageTitle: 'Ranap Pasien Restrain',
      objName: 'Ranap Pasien Restrain',
      isDetailRanap: true
    },
  },

  // Formulir Lainnya
  {
    path: 'formulir-lainnya-perawat/:pageSlug?/:type?/:id?',
    name: 'RanapFormulirLainnyaPerawat',
    component: () => import('@/views/backend/RanapFormulirLainnyaPerawat/List.vue'),
    meta: {
      menu: 'RanapFormulirLainnyaPerawat',
      pageTitle: 'Formulir lainnya',
      objName: 'Formulir lainnya',
      isDetailRanap: true
    },
  },
  {
    path: 'ranap-kajian-fibrinolisis/:pageSlug?/:kajianNo?',
    name: 'RanapPasienKajianStriptokinase',
    component: () => import('@/views/backend/RanapPasienKajianStriptokinase/List.vue'),
    meta: {
      menu: 'RanapPasienKajianStriptokinase',
      pageTitle: 'Ranap Pasien Monitoring Fibrinolisis',
      objName: 'Ranap Pasien Monitoring Fibrinolisis',
      isDetailRanap: true
    },
  },
  {
    path: 'ranap-laporan-insiden/:pageSlug?',
    name: 'RanapLaporanInsiden',
    component: () => import('@/views/backend/RanapLaporanInsiden/List.vue'),
    meta: {
      menu: 'RanapLaporanInsiden',
      pageTitle: 'Laporan Insiden Rawat Inap',
      objName: 'Laporan Insiden Rawat Inap',
    },
  },
  {
    path: 'ranap-hais/:pageSlug?',
    name: 'RanapHais',
    component: () => import('@/views/backend/RanapHais/List.vue'),
    meta: {
      menu: 'RanapHais',
      pageTitle: 'Ranap Hais',
      objName: 'Ranap Hais'
    },
  },
  {
    path: 'ranap-resume-medis/:pageSlug?',
    name: 'RanapResumeMedis',
    component: () => import('@/views/backend/RanapResumeMedis/Form.vue'),
    meta: {
      menu: 'RanapResumeMedis',
      pageTitle: 'Ranap Resume Medis',
      objName: 'Ranap Resume Medis'
    },
  },
  {
    path: 'ranap-rekonsiliasi-obat/:pageSlug?',
    name: 'RanapRekonsiliasi',
    component: () => import('@/views/backend/RanapRekonsiliasi/List.vue'),
    meta: {
      menu: 'RanapRekonsiliasi',
      pageTitle: 'Ranap Rekonsiliasi',
      objName: 'Ranap Rekonsiliasi',
    },
  },
  {
    path: 'kyc-verification/:pageSlug?',
    name: 'SatuSehatKYCVerification',
    component: () => import('@/views/backend/SatuSehatKYCVerification/View.vue'),
    meta: {
      menu: "SatuSehatKYCVerification",
      pageTitle: 'KYC SatuSehat',
      objName: 'KYC SatuSehat'
    },
  }, 
  {
    path: 'penerbitan-surat/:pageSlug?',
    name: 'PenerbitanSurat',
    component: () => import('@/views/backend/PenerbitanSurat/List.vue'),
    meta: {
      menu: "PenerbitanSurat",
      pageTitle: 'Penerbitan Surat',
      objName: 'Penerbitan Surat'
    },
  }, 
  {
    path: 'ranap-rencana-ranap/:pageSlug?',
    name: 'RanapRencanaRanap',
    component: () => import('@/views/backend/RanapRencanaRanap/List.vue'),
    meta: {
      menu: 'RanapRencanaRanap',
      pageTitle: 'Ranap Rencana Ranap',
      objName: 'Ranap Rencana Ranap',
    },
  },
  {
    path: 'ranap-rekam-medis/:pageSlug?',
    name: 'RanapRekamMedis',
    component: () => import('@/views/backend/RanapRekamMedis/List.vue'),
    meta: {
      menu: 'RanapRekamMedis',
      pageTitle: 'Ranap Rekam Medis',
      objName: 'Ranap Rekam Medis',
    },
  },
  {
    path: 'ranap-upla/:pageSlug?',
    name: 'RanapUPLA',
    component: () => import('@/views/backend/RanapUPLA/List.vue'),
    meta: {
      menu: 'RanapUPLA',
      pageTitle: 'Ranap UPLA',
      objName: 'Ranap UPLA',
    },
  },
  {
    path: 'pemeriksaan-pa/:pageSlug?',
    name: 'RanapPA',
    component: () => import('@/views/backend/RanapPA/List.vue'),
    meta: {
      menu: 'RanapPA',
      pageTitle: 'Pemeriksaan PA',
      objName: 'Pemeriksaan PA',
    },
  }, {
    path: 'verifikasi-pasien-selesai/:pageSlug?',
    name: 'RanapVerifPasienSelesai',
    component: () => import('@/views/backend/RanapVerifPasienSelesai/List.vue'),
    meta: {
      menu: 'RanapVerifPasienSelesai',
      pageTitle: 'Verifikasi Pasien Selesai',
      objName: 'Verifikasi Pasien Selesai',
    },
  }, {
    path: 'verifikasi-piutang/:pageSlug?',
    name: 'RanapVerifPiutang',
    component: () => import('@/views/backend/RanapVerifPiutang/List.vue'),
    meta: {
      menu: 'RanapVerifPiutang',
      pageTitle: 'Verifikasi Piutang',
      objName: 'Verifikasi Piutang',
    },
  },
  {
    path: 'satu-sehat-encounter/:pageSlug?',
    name: 'SatuSehatEncounter',
    component: () => import('@/views/backend/SatuSehatEncounter/List.vue'),
    meta: {
      menu: "SatuSehatEncounter",
      pageTitle: 'Encounter SatuSehat',
      objName: 'Encounter SatuSehat'
    },
  },
  {
    path: 'satu-sehat-encounter-ugd/:pageSlug?',
    name: 'SatuSehatEncounterUGD',
    component: () => import('@/views/backend/SatuSehatEncounterUGD/List.vue'),
    meta: {
      menu: "SatuSehatEncounterUGD",
      pageTitle: 'Encounter SatuSehat UGD',
      objName: 'Encounter SatuSehat UGD'
    },
  },
  {
    path: 'satu-sehat-encounter-ranap/:pageSlug?',
    name: 'SatuSehatEncounterRanap',
    component: () => import('@/views/backend/SatuSehatEncounterRanap/List.vue'),
    meta: {
      menu: "SatuSehatEncounterRanap",
      pageTitle: 'Encounter SatuSehat Ranap',
      objName: 'Encounter SatuSehat Ranap'
    },
  },
  {
    path: 'template-resep/:pageSlug?',
    name: 'MTemplateResep',
    component: () => import('@/views/backend/MTemplateResep/List.vue'),
    meta: {
      menu: "MTemplateResep",
      pageTitle: 'Master Template Resep',
      objName: 'Master Template Resep',
      hasCta: 'true',
    },
  },
  {
    path: 'template-penunjang/:pageSlug?',
    name: 'MTemplatePenunjang',
    component: () => import('@/views/backend/MTemplatePenunjang/List.vue'),
    meta: {
      menu: "MTemplatePenunjang",
      pageTitle: 'Master Template Penunjang',
      objName: 'Master Template Penunjang',
      hasCta: 'true',
    },
  },
  {
    path: 'edukasi-terintegrasi/:pageSlug?',
    name: 'UGDET',
    component: () => import('@/views/backend/UGDET/List.vue'),
    meta: {
      menu: "UGDET",
      pageTitle: 'Edukasi Terintegrasi',
      objName: 'Edukasi Terintegrasi',
    }, 
  },
  {
    path: 'batalkan-tindakan-penunjang',
    name: 'RoBatalkanTindakanPenunjang',
    component: () => import('@/views/backend/RoBatalkanTindakanPenunjang/List.vue'),
    meta: {
      menu: "RoBatalkanTindakanPenunjang",
      pageTitle: 'Batalkan Tindakan Penunjang',
      objName: 'Batalkan Tindakan Penunjang',
    },  
  },
  {
    path: 'poct-laboratorium/:pageSlug?',
    name: 'POCTLab',
    component: () => import('@/views/backend/POCTLab/List.vue'),
    meta: {
      menu: 'POCTLab',
      pageTitle: 'POCT Laboratorium',
      objName: 'POCT Laboratorium',
    },
  },
  
  {
    path: 'ugd-cppt-dokter-spesialist/:pageSlug?/:kajianNo?',
    name: 'UGDCPPTDokterSpesialist',
    component: () => import('@/views/backend/UGDCPPTDokterSpesialist/List.vue'),
    meta: {
      menu: "UGDCPPTDokterSpesialist",
      pageTitle: 'CPPT Dokter Spesialist',
      objName: 'CPPT Dokter Spesialist',
    },  
  },
  
  {
    path: 'master-flag-aktivitas-jadwal/:pageSlug?',
    name: 'MFlagAktivitasJadwal',
    component: () => import('@/views/backend/MFlagAktivitasJadwal/List.vue'),
    meta: {
      menu: "MFlagAktivitasJadwal",
      pageTitle: 'Master Flag Aktivitas Jadwal',
      objName: 'Master Flag Aktivitas Jadwal',
      hasCta: 'true',
    },
  },
  {
    path: 'laporan-efektivitas-jadwal/:pageSlug?',
    name: 'RoLaporanEfektivitasJadwal',
    component: () => import('@/views/backend/RoLaporanEfektivitasJadwal/List.vue'),
    meta: {
      menu: "RoLaporanEfektivitasJadwal",
      pageTitle: 'Laporan Efektivitas Jadwal',
      objName: 'Laporan Efektivitas Jadwal',
      hasCta: 'true',
    },
  },  
  {
    path: 'pasien-tindakan-icd-9/:pageSlug?',
    name: 'RoPasienTindakanICD9',
    component: () => import('@/views/backend/RoPasienTindakanICD9/List.vue'),
    meta: {
      menu: "RoPasienTindakanICD9",
      pageTitle: 'Pasien Tindakan Dokter',
      objName: 'Pasien Tindakan Dokter'
    },
  },  
  {
    path: 'pasien-umum/:pageSlug?',
    name: 'RoPasienUmum',
    component: () => import('@/views/backend/RoPasienUmum/View.vue'),
    meta: {
      menu: "RoPasienUmum",
      pageTitle: 'Pasien Umum',
      objName: 'Pasien Umum'
    },
  },  
  {
    path: 'logistik-unit/:pageSlug?',
    name: 'MLogistikUnit',
    component: () => import('@/views/backend/MLogistikUnit/List.vue'),
    meta: {
      menu: "MLogistikUnit",
      pageTitle: 'Logistik Unit',
      objName: 'Logistik Unit',
      hasCta: 'true',
    },
  },
  {
    path: 'logistik-sub-unit/:pageSlug?',
    name: 'MLogistikSubUnit',
    component: () => import('@/views/backend/MLogistikSubUnit/List.vue'),
    meta: {
      menu: "MLogistikSubUnit",
      pageTitle: 'Logistik Sub Unit',
      objName: 'Logistik Sub Unit',
      hasCta: 'true',
    },
  },
  {
    path: 'logistik-aset-bidang/:pageSlug?',
    name: 'MLogistikBarangAsetBidang',
    component: () => import('@/views/backend/MLogistikBarangAsetBidang/List.vue'),
    meta: {
      menu: "MLogistikBarangAsetBidang",
      pageTitle: 'Logistik Aset Bidang',
      objName: 'Logistik Aset Bidang',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-aset-kategori/:pageSlug?',
    name: 'MLogistikBarangAsetKategori',
    component: () => import('@/views/backend/MLogistikBarangAsetKategori/List.vue'),
    meta: {
      menu: "MLogistikBarangAsetKategori",
      pageTitle: 'Logistik Aset Kategori',
      objName: 'Logistik Aset Kategori',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-aset-merk/:pageSlug?',
    name: 'MLogistikBarangAsetMerk',
    component: () => import('@/views/backend/MLogistikBarangAsetMerk/List.vue'),
    meta: {
      menu: "MLogistikBarangAsetMerk",
      pageTitle: 'Logistik Aset Merk',
      objName: 'Logistik Aset Merk',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-aset-barang/:pageSlug?',
    name: 'MLogistikBarangAset',
    component: () => import('@/views/backend/MLogistikBarangAset/List.vue'),
    meta: {
      menu: "MLogistikBarangAset",
      pageTitle: 'Logistik Aset Barang',
      objName: 'Logistik Aset Barang',
      hasCta: 'true',
    }, 
  },

  {
    path: 'logistik-gudang-master/:pageSlug?',
    name: 'MLogistikGudang',
    component: () => import('@/views/backend/MLogistikGudang/List.vue'),
    meta: {
      menu: "MLogistikGudang",
      pageTitle: 'Logistik Gudang',
      objName: 'Logistik Gudang',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-kategori/:pageSlug?',
    name: 'MLogistikKategori',
    component: () => import('@/views/backend/MLogistikKategori/List.vue'),
    meta: {
      menu: "MLogistikKategori",
      pageTitle: 'Logistik Kategori',
      objName: 'Logistik Kategori',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-barang-umum/:pageSlug?',
    name: 'MLogistikBarangUmum',
    component: () => import('@/views/backend/MLogistikBarangUmum/List.vue'),
    meta: {
      menu: "MLogistikBarangUmum",
      pageTitle: 'Logistik Umum',
      objName: 'Logistik Umum',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-barang-umum-kategori/:pageSlug?',
    name: 'MLogistikBarangUmumKategori',
    component: () => import('@/views/backend/MLogistikBarangUmumKategori/List.vue'),
    meta: {
      menu: "MLogistikBarangUmumKategori",
      pageTitle: 'Logistik Kategori',
      objName: 'Logistik Kategori',
      hasCta: 'true',
    }, 
  },
  {
    path: 'tarif-komponen/:pageSlug?',
    name: 'MTarifKomponen',
    component: () => import('@/views/backend/MTarifKomponen/List.vue'),
    meta: {
      menu: "MTarifKomponen",
      pageTitle: 'Komponen Tarif',
      objName: 'Komponen Tarif',
      hasCta: 'true',
    }, 
  },
  {
    path: 'ncc-mapping/:pageSlug?',
    name: 'MNCCMapping',
    component: () => import('@/views/backend/MNCCMapping/List.vue'),
    meta: {
      menu: "MNCCMapping",
      pageTitle: 'NCC Mapping',
      objName: 'NCC Mapping',
    }, 
  },
  {
    path: 'config-tarif/:pageSlug?',
    name: 'AppKonfigurasiTarif',
    component: () => import('@/views/backend/AppKonfigurasiTarif/List.vue'),
    meta: {
      menu: "AppKonfigurasiTarif",
      pageTitle: 'Konfigurasi Tarif',
      objName: 'Konfigurasi Tarif',
    }, 
  },
  {

    path: 'logistik-industri/:pageSlug?',
    name: 'MLogistikSupplierIndustri',
    component: () => import('@/views/backend/MLogistikSupplierIndustri/List.vue'),
    meta: {
      menu: "MLogistikSupplierIndustri",
      pageTitle: 'Logistik Industri',
      objName: 'Logistik Industri',
      hasCta: 'true',
    }, 
  },
  {

    path: 'logistik-supplier/:pageSlug?',
    name: 'MLogistikSupplier',
    component: () => import('@/views/backend/MLogistikSupplier/List.vue'),
    meta: {
      menu: "MLogistikSupplier",
      pageTitle: 'Logistik Supplier',
      objName: 'Logistik Supplier',
      hasCta: 'true',
    }, 
  },
  {

    path: 'master-logistik-anggaran/:pageSlug?',
    name: 'MLogistikKategoriAnggaran',
    component: () => import('@/views/backend/MLogistikKategoriAnggaran/List.vue'),
    meta: {
      menu: "MLogistikKategoriAnggaran",
      pageTitle: 'Logistik Anggaran',
      objName: 'Logistik Anggaran',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-material-request/:pageSlug?',
    name: 'LogistikMaterialRequest',
    component: () => import('@/views/backend/LogistikMaterialRequest/List.vue'),
    meta: {
      menu: "LogistikMaterialRequest",
      pageTitle: 'Logistik Masterial Request',
      objName: 'Logistik Masterial Request',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-purchase-request/:pageSlug?',
    name: 'LogistikPurchaseRequest',
    component: () => import('@/views/backend/LogistikPurchaseRequest/List.vue'),
    meta: {
      menu: "LogistikPurchaseRequest",
      pageTitle: 'Logistik Purchase Request',
      objName: 'Logistik Purchase Request',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-approval-anggaran/:pageSlug?',
    name: 'LogistikApprovalAnggaran',
    component: () => import('@/views/backend/LogistikApprovalAnggaran/List.vue'),
    meta: {
      menu: "LogistikApprovalAnggaran",
      pageTitle: 'Logistik Approval Anggaran',
      objName: 'Logistik Approval Anggaran',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-anggaran/:pageSlug?',
    name: 'LogistikAnggaran',
    component: () => import('@/views/backend/LogistikAnggaran/List.vue'),
    meta: {
      menu: "LogistikAnggaran",
      pageTitle: 'Logistik Anggaran',
      objName: 'Logistik Anggaran',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-po-request/:pageSlug?',
    name: 'LogistikPORequest',
    component: () => import('@/views/backend/LogistikPORequest/List.vue'),
    meta: {
      menu: "LogistikPORequest",
      pageTitle: 'Logistik PO Request',
      objName: 'Logistik PO Request',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-po-list/:pageSlug?',
    name: 'LogistikPOList',
    component: () => import('@/views/backend/LogistikPOList/List.vue'),
    meta: {
      menu: "LogistikPOList",
      pageTitle: 'Logistik PO List',
      objName: 'Logistik PO List',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-po-approval/:pageSlug?',
    name: 'LogistikPOApproval',
    component: () => import('@/views/backend/LogistikPOApproval/List.vue'),
    meta: {
      menu: "LogistikPOApproval",
      pageTitle: 'Logistik PO Approval',
      objName: 'Logistik PO Approval',
      hasCta: 'true',
    }, 
  },
  
  {
    path: 'logistik-do/:pageSlug?',
    name: 'LogistikDOList',
    component: () => import('@/views/backend/LogistikDOList/List.vue'),
    meta: {
      menu: "LogistikDOList",
      pageTitle: 'Logistik DO List',
      objName: 'Logistik DO List',
      hasCta: 'true',
    }, 
  },
  
  {
    path: 'logistik-request-do/:pageSlug?',
    name: 'LogistikDORequest',
    component: () => import('@/views/backend/LogistikDORequest/List.vue'),
    meta: {
      menu: "LogistikDORequest",
      pageTitle: 'Logistik DO Request',
      objName: 'Logistik DO Request',
      hasCta: 'true',
    }, 
  },
  
  {
    path: 'logistik-berita-acara/:pageSlug?',
    name: 'LogistikBeritaAcara',
    component: () => import('@/views/backend/LogistikBeritaAcara/List.vue'),
    meta: {
      menu: "LogistikBeritaAcara",
      pageTitle: 'Logistik Berita Acara',
      objName: 'Logistik Berita Acara',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-mutasi/:pageSlug?',
    name: 'LogistikMutasi',
    component: () => import('@/views/backend/LogistikMutasi/List.vue'),
    meta: {
      menu: "LogistikMutasi",
      pageTitle: 'Logistik Mutasi',
      objName: 'Logistik Mutasi',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-approval-mutasi/:pageSlug?',
    name: 'LogistikApprovalMutasi',
    component: () => import('@/views/backend/LogistikApprovalMutasi/List.vue'),
    meta: {
      menu: "LogistikApprovalMutasi",
      pageTitle: 'Logistik Approval Mutasi',
      objName: 'Logistik Approval Mutasi',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-verifikasi-mutasi-anggaran/:pageSlug?',
    name: 'LogistikMutasiVerifikasiAnggaran',
    component: () => import('@/views/backend/LogistikMutasiVerifikasiAnggaran/List.vue'),
    meta: {
      menu: "LogistikMutasiVerifikasiAnggaran",
      pageTitle: 'Logistik Verifikasi Anggaran',
      objName: 'Logistik Verifikasi Anggaran',
      hasCta: 'true',
    }, 
  },
  {
    path: 'logistik-stok-unit/:pageSlug?',
    name: 'LogistikStokUnit',
    component: () => import('@/views/backend/LogistikStokUnit/List.vue'),
    meta: {
      menu: "LogistikStokUnit",
      pageTitle: 'Logistik Stok Unit',
      objName: 'Logistik Stok Unit',
      hasCta: 'true',
    }, 
  },
  
  {
    path: 'logistik-gudang-penarikan/:pageSlug?',
    name: 'LogistikGudangPenarikan',
    component: () => import('@/views/backend/LogistikGudangPenarikan/List.vue'),
    meta: {
      menu: "LogistikGudangPenarikan",
      pageTitle: 'Logistik Gudang Penarikan',
      objName: 'Logistik Gudang Penarikan',
    }, 
  },
  
  {
    path: 'logistik-penarikan-barang/:pageSlug?',
    name: 'LogistikPenarikanBarang',
    component: () => import('@/views/backend/LogistikPenarikanBarang/List.vue'),
    meta: {
      menu: "LogistikPenarikanBarang",
      pageTitle: 'Logistik Penarikan Barang',
      objName: 'Logistik Penarikan Barang',
    }, 
  },
  
  {
    path: 'logistik-gudang/:pageSlug?',
    name: 'LogistikGudang',
    component: () => import('@/views/backend/LogistikGudang/List.vue'),
    meta: {
      menu: "LogistikGudang",
      pageTitle: 'Logistik Gudang',
      objName: 'Logistik Gudang',
    }, 
  },
  {
    path: 'logistik-barang-repack/:pageSlug?',
    name: 'LogistikBarangRepack',
    component: () => import('@/views/backend/LogistikBarangRepack/List.vue'),
    meta: {
      menu: "LogistikBarangRepack",
      pageTitle: 'Logistik Barang Repack',
      objName: 'Logistik Barang Repack',
    }, 
  },
  {
    path: 'logistik-pembayaran-hutang/:pageSlug?',
    name: 'LogistikPembayaranHutang',
    component: () => import('@/views/backend/LogistikPembayaranHutang/List.vue'),
    meta: {
      menu: "LogistikPembayaranHutang",
      pageTitle: 'Pembayaran Hutang',
      objName: 'Pembayaran Hutang',
      hasCta: true
    }, 
  },
  
  {
    path: 'logistik-pembayaran-hutang-do/:pageSlug?',
    name: 'LogistikPembayaranHutangDO',
    component: () => import('@/views/backend/LogistikPembayaranHutangDO/List.vue'),
    meta: {
      menu: "LogistikPembayaranHutangDO",
      pageTitle: 'Pembayaran Hutang DO',
      objName: 'Pembayaran Hutang DO',
      hasCta: true
    }, 
  },
  {
    path: 'verifikasi-jurnal-keuangan/:pageSlug?',
    name: 'VerifikasiJurnalKeuangan',
    component: () => import('@/views/backend/VerifikasiJurnalKeuangan/List.vue'),
    meta: {
      menu: "VerifikasiJurnalKeuangan",
      pageTitle: 'Verifikasi Jurnal Keuangan',
      objName: 'Verifikasi Jurnal Keuangan',
      hasCta: true
    }, 
  },
  {
    path: 'logistik-return/:pageSlug?',
    name: 'LogistikReturn',
    component: () => import('@/views/backend/LogistikReturn/List.vue'),
    meta: {
      menu: "LogistikReturn",
      pageTitle: 'Logistik Return',
      objName: 'Logistik Return'
    }, 
  },
  {
    path: 'master-coa-pendapatan/:pageSlug?',
    name: 'MCOAPendapatan',
    component: () => import('@/views/backend/MCOAPendapatan/List.vue'),
    meta: {
      menu: "MCOAPendapatan",
      pageTitle: 'Master COA Pendapatan',
      objName: 'Master COA Pendapatan',
      hasCta: true
    }, 
  },
  {
    path: 'verifikasi-jurnal-rajal/:pageSlug?',
    name: 'BoBillingVerifikasiRAJAL',
    component: () => import('@/views/backend/BoBillingVerifikasiRAJAL/List.vue'),
    meta: {
      menu: "BoBillingVerifikasiRAJAL",
      pageTitle: 'Verifikasi Jurnal',
      objName: 'Verifikasi Jurnal',
    }, 
  },
  {
    path: 'verifikasi-jurnal-ugd/:pageSlug?',
    name: 'BoBillingVerifikasiUGD',
    component: () => import('@/views/backend/BoBillingVerifikasiUGD/List.vue'),
    meta: {
      menu: "BoBillingVerifikasiUGD",
      pageTitle: 'Verifikasi Jurnal',
      objName: 'Verifikasi Jurnal',
    }, 
  },
  {
    path: 'verifikasi-jurnal-rawat-inap/:pageSlug?',
    name: 'BoBillingVerifikasiRanap',
    component: () => import('@/views/backend/BoBillingVerifikasiRanap/List.vue'),
    meta: {
      menu: "BoBillingVerifikasiRanap",
      pageTitle: 'Verifikasi Jurnal',
      objName: 'Verifikasi Jurnal',
    }, 
  },
  {
    path: 'verifikasi-jurnal-logistik/:pageSlug?',
    name: 'VerifikasiJurnalLogistik',
    component: () => import('@/views/backend/VerifikasiJurnalLogistik/List.vue'),
    meta: {
      menu: "VerifikasiJurnalLogistik",
      pageTitle: 'Verifikasi Jurnal Logistik',
      objName: 'Verifikasi Jurnal Logistik',
    }, 
  },
  {
    path: 'logistik-pemusnahan-barang/:pageSlug?',
    name: 'LogistikPemusnahanBarang',
    component: () => import('@/views/backend/LogistikPemusnahanBarang/List.vue'),
    meta: {
      menu: "LogistikPemusnahanBarang",
      pageTitle: 'Pemusnahan Barang',
      objName: 'Pemusnahan Barang',
    }, 
  },
  {
    path: 'config-logistik',
    name: 'MLogistikConfig',
    component: () => import('@/views/backend/MLogistikConfig/List.vue'),
    meta: {
      menu: "MLogistikConfig",
      pageTitle: 'Logistik Config',
      objName: 'Logistik Config',
    }, 
  },

  
  

  
  
  

  
  

  // LogistikApprovalAnggaran

  
  
  {
    path: 'test',
    name: 'Test',
    component: () => import('@/views/backend/Test/List.vue'),
    meta: {
      menu: "Test",
      pageTitle: 'TEST',
      objName: 'TEST',
      hasCta: 'true',
    }, 
  },

  {
    path: 'finger-print-data/:pageSlug?',
    name: 'FingerPrint',
    component: () => import('@/views/backend/FingerPrint/List.vue'),
    meta: {
      menu: "FingerPrint",
      pageTitle: 'Finger Print Data',
      objName: 'Finger Print Data'
    }, 
  },


  {
    path: 'tarif-registrasi/:pageSlug?',
    name: 'BoBillingTarifRegistrasi',
    component: () => import('@/views/backend/BoBillingTarifRegistrasi/List.vue'),
    meta: {
      menu: "BoBillingTarifRegistrasi",
      pageTitle: 'Tarif Registrasi',
      objName: 'Tarif Registrasi',
    }, 
  },

  {
    path: 'tarif-akomodasi/:pageSlug?',
    name: 'BoBillingTarifAkomodasi',
    component: () => import('@/views/backend/BoBillingTarifAkomodasi/List.vue'),
    meta: {
      menu: "BoBillingTarifAkomodasi",
      pageTitle: 'Tarif Akomodasi',
      objName: 'Tarif Akomodasi',
      hasCta: true
    }, 
  },

  {
    path: 'tarif-periksa-dokter/:pageSlug?',
    name: 'BoBillingTarifPeriksaDokter',
    component: () => import('@/views/backend/BoBillingTarifPeriksaDokter/List.vue'),
    meta: {
      menu: "BoBillingTarifPeriksaDokter",
      pageTitle: 'Tarif Periksa Dokter',
      objName: 'Tarif Periksa Dokter',
    }, 
  },

  {
    path: 'tarif-visit-dokter-spesialis/:pageSlug?',
    name: 'BoBillingTarifVisitRawatInap',
    component: () => import('@/views/backend/BoBillingTarifVisitRawatInap/List.vue'),
    meta: {
      menu: "BoBillingTarifVisitRawatInap",
      pageTitle: 'Tarif Visit Dokter Spesialis',
      objName: 'Tarif Visit Dokter Spesialis',
      hasCta: true
    }, 
  },

  {
    path: 'tarif-tindakan-dokter-spesialis/:pageSlug?',
    name: 'BoBillingTarifTindakanDokterSpesialis',
    component: () => import('@/views/backend/BoBillingTarifTindakanDokterSpesialis/List.vue'),
    meta: {
      menu: "BoBillingTarifTindakanDokterSpesialis",
      pageTitle: 'Tarif Tindakan Dokter Spesialis',
      objName: 'Tarif Tindakan Dokter Spesialis',
      hasCta: true
    }, 
  },
  {
    path: 'tarif-tindakan-dokter-umum/:pageSlug?',
    name: 'BoBillingTarifTindakanDokterUmum',
    component: () => import('@/views/backend/BoBillingTarifTindakanDokterUmum/List.vue'),
    meta: {
      menu: "BoBillingTarifTindakanDokterUmum",
      pageTitle: 'Tarif Tindakan Dokter Umum',
      objName: 'Tarif Tindakan Dokter Umum',
      hasCta: true
    }, 
  },
  {
    path: 'tarif-tindakan-perawat/:pageSlug?',
    name: 'BoBillingTarifTindakanPerawat',
    component: () => import('@/views/backend/BoBillingTarifTindakanPerawat/List.vue'),
    meta: {
      menu: "BoBillingTarifTindakanPerawat",
      pageTitle: 'Tarif Tindakan Perawat',
      objName: 'Tarif Tindakan Perawat',
      hasCta: true
    }, 
  },

  {
    path: 'tarif-visit-dokter-umum/:pageSlug?',
    name: 'BoBillingTarifVisitDokterUmum',
    component: () => import('@/views/backend/BoBillingTarifVisitDokterUmum/List.vue'),
    meta: {
      menu: "BoBillingTarifVisitDokterUmum",
      pageTitle: 'Tarif Visit Dokter Umum',
      objName: 'Tarif Visit Dokter Umum',
    }, 
  },

  {
    path: 'tarif-konsul-online/:pageSlug?',
    name: 'BoBillingTarifKonsulOnline',
    component: () => import('@/views/backend/BoBillingTarifKonsulOnline/List.vue'),
    meta: {
      menu: "BoBillingTarifKonsulOnline",
      pageTitle: 'Tarif Konsultasi Online',
      objName: 'Tarif Konsultasi Online',
    }, 
  },
  {
    path: 'tarif-konsul-online/:pageSlug?',
    name: 'BoBillingTarifKonsulOnline',
    component: () => import('@/views/backend/BoBillingTarifKonsulOnline/List.vue'),
    meta: {
      menu: "BoBillingTarifKonsulOnline",
      pageTitle: 'Tarif Konsultasi Online',
      objName: 'Tarif Konsultasi Online',
    }, 
  },

  {
    path: 'tarif-konsul-ahli-gizi/:pageSlug?',
    name: 'BoBillingTarifKonsulAhliGizi',
    component: () => import('@/views/backend/BoBillingTarifKonsulAhliGizi/List.vue'),
    meta: {
      menu: "BoBillingTarifKonsulAhliGizi",
      pageTitle: 'Tarif Konsultasi Ahli Gizi',
      objName: 'Tarif Konsultasi Ahli Gizi',
    }, 
  },

  {
    path: 'tarif-ambulance/:pageSlug?',
    name: 'BoBillingTarifAmbulance',
    component: () => import('@/views/backend/BoBillingTarifAmbulance/List.vue'),
    meta: {
      menu: "BoBillingTarifAmbulance",
      pageTitle: 'Tarif Ambulance',
      objName: 'Tarif Ambulance',
    }, 
  },

  {
    path: 'tarif-radiologi/:pageSlug?',
    name: 'BoBillingTarifRadiologi',
    component: () => import('@/views/backend/BoBillingTarifRadiologi/List.vue'),
    meta: {
      menu: "BoBillingTarifRadiologi",
      pageTitle: 'Tarif Radiologi',
      objName: 'Tarif Radiologi',
      hasCta: true
    }, 
  },
  {
    path: 'tarif-radiologi-external/:pageSlug?',
    name: 'BoBillingTarifRadiologiExternal',
    component: () => import('@/views/backend/BoBillingTarifRadiologiExternal/List.vue'),
    meta: {
      menu: "BoBillingTarifRadiologiExternal",
      pageTitle: 'Tarif Radiologi - Eksternal',
      objName: 'Tarif Radiologi - Eksternal',
      hasCta: true
    }, 
  },
  {
    path: 'tarif-tindakan-operasi/:pageSlug?',
    name: 'BoBillingTarifTindakanOperasi',
    component: () => import('@/views/backend/BoBillingTarifTindakanOperasi/List.vue'),
    meta: {
      menu: "BoBillingTarifTindakanOperasi",
      pageTitle: 'Tarif Tindakan Operasi',
      objName: 'Tarif Tindakan Operasi',
      hasCta: true
    }, 
  },
  {
    path: 'tarif-fisioterapi/:pageSlug?',
    name: 'BoBillingTarifFisioterapi',
    component: () => import('@/views/backend/BoBillingTarifFisioterapi/List.vue'),
    meta: {
      menu: "BoBillingTarifFisioterapi",
      pageTitle: 'Tarif Fisioterapi',
      objName: 'Tarif Fisioterapi',
      hasCta: true
    }, 
  },
  {
    path: 'tarif-lab-external/:pageSlug?',
    name: 'BoBillingTarifLabExternal',
    component: () => import('@/views/backend/BoBillingTarifLabExternal/List.vue'),
    meta: {
      menu: "BoBillingTarifLabExternal",
      pageTitle: 'Tarif Lab - Eksternal',
      objName: 'Tarif Lab - Eksternal',
      hasCta: true
    }, 
  },
  {
    path: 'tarif-lab/:pageSlug?',
    name: 'BoBillingTarifLab',
    component: () => import('@/views/backend/BoBillingTarifLab/List.vue'),
    meta: {
      menu: "BoBillingTarifLab",
      pageTitle: 'Tarif Lab - Internal',
      objName: 'Tarif Lab - Internal',
      hasCta: true
    },     
  },
  {
    path: 'tarif-laundry/:pageSlug?',
    name: 'BoBillingTarifLaundry',
    component: () => import('@/views/backend/BoBillingTarifLaundry/List.vue'),
    meta: {
      menu: "BoBillingTarifLaundry",
      pageTitle: 'Tarif Laundry',
      objName: 'Tarif Laundry',
    },     
  },
  {
    path: 'tarif-medical-report/:pageSlug?',
    name: 'BoBillingTarifMedicalReport',
    component: () => import('@/views/backend/BoBillingTarifMedicalReport/List.vue'),
    meta: {
      menu: "BoBillingTarifMedicalReport",
      pageTitle: 'Tarif Medical Report',
      objName: 'Tarif Medical Report',
    },     
  },
  {
    path: 'tarif-gizi/:pageSlug?',
    name: 'BoBillingTarifGizi',
    component: () => import('@/views/backend/BoBillingTarifGizi/List.vue'),
    meta: {
      menu: "BoBillingTarifGizi",
      pageTitle: 'Tarif Gizi',
      objName: 'Tarif Gizi',
      hasCta: true
    },     
  },
  {
    path: 'tarif-mcu/:pageSlug?',
    name: 'BoBillingTarifMCU',
    component: () => import('@/views/backend/BoBillingTarifMCU/List.vue'),
    meta: {
      menu: "BoBillingTarifMCU",
      pageTitle: 'Tarif MCU',
      objName: 'Tarif MCU',
      hasCta: true
    },     
  },
  {
    path: 'tarif-home-care/:pageSlug?',
    name: 'BoBillingTarifHomeCare',
    component: () => import('@/views/backend/BoBillingTarifHomeCare/List.vue'),
    meta: {
      menu: "BoBillingTarifHomeCare",
      pageTitle: 'Tarif Home Care',
      objName: 'Tarif Home Care',
      hasCta: true
    },     
  },
  {
    path: 'tarif-tambahan-dietisien/:pageSlug?',
    name: 'RanapTarifTambahanDietisien',
    component: () => import('@/views/backend/RanapTarifTambahanDietisien/List.vue'),
    meta: {
      menu: "RanapTarifTambahanDietisien",
      pageTitle: 'Tarif Tambahan Gizi',
      objName: 'Tarif Tambahan Gizi',
    },     
  },
  {
    path: 'tarif-transfusi/:pageSlug?',
    name: 'BoBillingTarifTransfusi',
    component: () => import('@/views/backend/BoBillingTarifTransfusi/List.vue'),
    meta: {
      menu: "BoBillingTarifTransfusi",
      pageTitle: 'Tarif Transfusi',
      objName: 'Tarif Transfusi',
      hasCta: true
    },     
  },
  {
    path: 'tarif-phlebotomy/:pageSlug?',
    name: 'BoBillingTarifPhlebotomy',
    component: () => import('@/views/backend/BoBillingTarifPhlebotomy/List.vue'),
    meta: {
      menu: "BoBillingTarifPhlebotomy",
      pageTitle: 'Tarif Phlebotomy',
      objName: 'Tarif Phlebotomy',
    },     
  },
  {
    path: 'tarif-pa/:pageSlug?',
    name: 'BoBillingTarifPA',
    component: () => import('@/views/backend/BoBillingTarifPA/List.vue'),
    meta: {
      menu: "BoBillingTarifPA",
      pageTitle: 'Tarif Pemeriksaan PA',
      objName: 'Tarif Pemeriksaan PA',
    },     
  },
  {
    path: 'tarif-pemeriksaan-hiv/:pageSlug?',
    name: 'BoBillingTarifPemeriksaanHIV',
    component: () => import('@/views/backend/BoBillingTarifPemeriksaanHIV/List.vue'),
    meta: {
      menu: "BoBillingTarifPemeriksaanHIV",
      pageTitle: 'Tarif Pemeriksaan HIV',
      objName: 'Tarif Pemeriksaan HIV',
    },     
  },
  {
    path: 'tarif-crossmatch/:pageSlug?',
    name: 'BoBillingTarifCrossmatch',
    component: () => import('@/views/backend/BoBillingTarifCrossmatch/List.vue'),
    meta: {
      menu: "BoBillingTarifCrossmatch",
      pageTitle: 'Tarif Crossmatch',
      objName: 'Tarif Crossmatch',
    },     
  },

  // Medical Report
  {
    path: 'ranap-medical-report/:pageSlug?',
    name: 'RanapMedicalReportRM',
    component: () => import('@/views/backend/RanapMedicalReportRM/List.vue'),
    meta: {
      menu: "RanapMedicalReportRM",
      pageTitle: 'Permintaan Medical Report',
      objName: 'Permintaan Medical Report',
    },     
  },
  {
    path: 'ugd-medical-report/:pageSlug?',
    name: 'UGDMedicalReportRM',
    component: () => import('@/views/backend/UGDMedicalReportRM/List.vue'),
    meta: {
      menu: "UGDMedicalReportRM",
      pageTitle: 'Permintaan Medical Report',
      objName: 'Permintaan Medical Report',
    },     
  },
  {
    path: 'poliklinik-medical-report/:pageSlug?',
    name: 'PoliklinikMedicalReportRM',
    component: () => import('@/views/backend/PoliklinikMedicalReportRM/List.vue'),
    meta: {
      menu: "PoliklinikMedicalReportRM",
      pageTitle: 'Permintaan Medical Report',
      objName: 'Permintaan Medical Report',
    },     
  },
  
  {
    path: 'master-logistik-vendor-pemusnahan/:pageSlug?',
    name: 'MLogistikVendorPemusnahan',
    component: () => import('@/views/backend/MLogistikVendorPemusnahan/List.vue'),
    meta: {
      menu: "MLogistikVendorPemusnahan",
      pageTitle: 'Master Logistik Vendor Pemusnahan',
      objName: 'Master Logistik Vendor Pemusnahan',
      hasCta: true
    },     
  },

  {
    path: 'verifikasi-kasir-jurnal-rajal/:pageSlug?',
    name: 'BoKasirVerifikasiRAJAL',
    component: () => import('@/views/backend/BoKasirVerifikasiRAJAL/List.vue'),
    meta: {
      menu: "BoKasirVerifikasiRAJAL",
      pageTitle: 'Verifikasi Jurnal Kasir',
      objName: 'Verifikasi Jurnal Kasir',
    }, 
  },
  {
    path: 'verifikasi-kasir-jurnal-ugd/:pageSlug?',
    name: 'BoKasirVerifikasiUGD',
    component: () => import('@/views/backend/BoKasirVerifikasiUGD/List.vue'),
    meta: {
      menu: "BoKasirVerifikasiUGD",
      pageTitle: 'Verifikasi Jurnal Kasir',
      objName: 'Verifikasi Jurnal Kasir',
    }, 
  },
  {
    path: 'verifikasi-kasir-jurnal-rawat-inap/:pageSlug?',
    name: 'BoKasirVerifikasiRanap',
    component: () => import('@/views/backend/BoKasirVerifikasiRanap/List.vue'),
    meta: {
      menu: "BoKasirVerifikasiRanap",
      pageTitle: 'Verifikasi Jurnal Kasir',
      objName: 'Verifikasi Jurnal Kasir',
    }, 
  },
  {
    path: 'tutup-kasir/:pageSlug?',
    name: 'BoTutupKasir',
    component: () => import('@/views/backend/BoTutupKasir/List.vue'),
    meta: {
      menu: "BoTutupKasir",
      pageTitle: 'Tutup Kasir',
      objName: 'Tutup Kasir',
      hasCta: true
    }
  },
  {
    path: 'stok-opname/:pageSlug?',
    name: 'LogistikStokOpName',
    component: () => import('@/views/backend/LogistikStokOpName/List.vue'),
    meta: {
      menu: "LogistikStokOpName",
      pageTitle: 'Logistik Stok Opname',
      objName: 'Logistik Stok Opname',
      hasCta: true
    }, 
  },
  {
    path: 'setup-saldo-awal/:pageSlug?',
    name: 'BoSetupSaldoAwal',
    component: () => import('@/views/backend/BoSetupSaldoAwal/List.vue'),
    meta: {
      menu: "BoSetupSaldoAwal",
      pageTitle: 'Setup Saldo Awal',
      objName: 'Setup Saldo Awal',
      hasCta: true
    }, 
  },
  {
    path: 'rekonsil-bank/:pageSlug?/:pageId?',
    name: 'BoBillingRekonsilBank',
    component: () => import('@/views/backend/BoBillingRekonsilBank/List.vue'),
    meta: {
      menu: "BoBillingRekonsilBank",
      pageTitle: 'Rekonsiliasi Bank',
      objName: 'Rekonsiliasi Bank',
      hasCta: true
    }, 
  },
  {
    path: 'setup-saldo-awal/:pageSlug?',
    name: 'BoSetupSaldoAwal',
    component: () => import('@/views/backend/BoSetupSaldoAwal/List.vue'),
    meta: {
      menu: "BoSetupSaldoAwal",
      pageTitle: 'Setup Saldo Awal',
      objName: 'Setup Saldo Awal',
      hasCta: true
    }, 
  },
  {
    path: 'verifikasi-jurnal-eklaim-poli/:pageSlug?',
    name: 'VerifikasiJurnalEKlaimPoli',
    component: () => import('@/views/backend/VerifikasiJurnalEKlaimPoli/List.vue'),
    meta: {
      menu: "VerifikasiJurnalEKlaimPoli",
      pageTitle: 'Verifikasi Jurnal E-Klaim Poli',
      objName: 'Verifikasi Jurnal E-Klaim Poli',
    }, 
  },
  {
    path: 'verifikasi-jurnal-eklaim-ugd/:pageSlug?',
    name: 'VerifikasiJurnalEKlaimUGD',
    component: () => import('@/views/backend/VerifikasiJurnalEKlaimUGD/List.vue'),
    meta: {
      menu: "VerifikasiJurnalEKlaimUGD",
      pageTitle: 'Verifikasi Jurnal E-Klaim UGD',
      objName: 'Verifikasi Jurnal E-Klaim UGD',
    }, 
  },
  {
    path: 'verifikasi-jurnal-eklaim-ranap/:pageSlug?',
    name: 'VerifikasiJurnalEKlaimRanap',
    component: () => import('@/views/backend/VerifikasiJurnalEKlaimRanap/List.vue'),
    meta: {
      menu: "VerifikasiJurnalEKlaimRanap",
      pageTitle: 'Verifikasi Jurnal E-Klaim Rawat Inap',
      objName: 'Verifikasi Jurnal E-Klaim Rawat Inap',
    }, 
  },
  {
    path: 'laporan-billing',
    name: 'LaporanBilling',
    component: () => import('@/views/backend/LaporanBilling/List.vue'),
    meta: {
      menu: "LaporanBilling",
      pageTitle: 'Laporan Billing',
      objName: 'Laporan Billing',
    }, 
  },
  {
    path: 'laporan-akuntansi',
    name: 'LaporanAkuntansi',
    component: () => import('@/views/backend/LaporanAkuntansi/List.vue'),
    meta: {
      menu: "LaporanAkuntansi",
      pageTitle: 'Laporan Akuntansi',
      objName: 'Laporan Akuntansi',
    }, 
  },
  {
    path: 'laporan-hutang',
    name: 'LaporanHutang',
    component: () => import('@/views/backend/LaporanHutang/List.vue'),
    meta: {
      menu: "LaporanHutang",
      pageTitle: 'Laporan Hutang',
      objName: 'Laporan Hutang',
    }, 
  },
  {
    path: 'laporan-hutang',
    name: 'LaporanHutang',
    component: () => import('@/views/backend/LaporanHutang/List.vue'),
    meta: {
      menu: "LaporanHutang",
      pageTitle: 'Laporan Hutang',
      objName: 'Laporan Hutang',
    }, 
  },
  {
    path: 'laporan-piutang',
    name: 'LaporanPiutang',
    component: () => import('@/views/backend/LaporanPiutang/List.vue'),
    meta: {
      menu: "LaporanPiutang",
      pageTitle: 'Laporan Piutang',
      objName: 'Laporan Piutang',
    }, 
  },
  {
    path: 'farmasi-jual-obat/:pageSlug?',
    name: 'RoFarmasiJualObat',
    component: () => import('@/views/backend/RoFarmasiJualObat/List.vue'),
    meta: {
      menu: "RoFarmasiJualObat",
      pageTitle: 'Farmasi Eksternal',
      objName: 'Farmasi Eksternal'
      // hasCta: 'true',
    }, 
  },

  
  {
    path: 'verifikasi-jurnal-eksternal-rajal/:pageSlug?',
    name: 'BoBillingEksternalVerifikasiRAJAL',
    component: () => import('@/views/backend/BoBillingEksternalVerifikasiRAJAL/List.vue'),
    meta: {
      menu: "BoBillingEksternalVerifikasiRAJAL",
      pageTitle: 'Jurnal Billing Eksternal Rajal',
      objName: 'Jurnal Billing Eksternal Rajal'
      // hasCta: 'true',
    }, 
  },
  
  {
    path: 'verifikasi-jurnal-kasir-eksternal-rajal/:pageSlug?',
    name: 'BoKasirEksternalVerifikasiRAJAL',
    component: () => import('@/views/backend/BoKasirEksternalVerifikasiRAJAL/List.vue'),
    meta: {
      menu: "BoKasirEksternalVerifikasiRAJAL",
      pageTitle: 'Jurnal Kasir Eksternal Rajal',
      objName: 'Jurnal Kasir Eksternal Rajal'
      // hasCta: 'true',
    }, 
  },

  {
    path: 'billing-kuitansi-non/:pageSlug?',
    name: 'BoBillingKwitansiNon',
    component: () => import('@/views/backend/BoBillingKwitansiNon/List.vue'),
    meta: {
      menu: "BoBillingKwitansiNon",
      pageTitle: 'Billing Kuitansi Non',
      objName: 'Billing Kuitansi Non'
      // hasCta: 'true',
    }, 
  },

  
  {
    path: 'billing-return-obat-farmasi/:pageSlug?',
    name: 'BillingReturnObatFarmasi',
    component: () => import('@/views/backend/BillingReturnObatFarmasi/List.vue'),
    meta: {
      menu: "BillingReturnObatFarmasi",
      pageTitle: 'Billing Return Obat Farmasi',
      objName: 'Billing Return Obat Farmasi'
      // hasCta: 'true',
    }, 
  },
  
  {
    path: 'billing-return-obat-kasir/:pageSlug?',
    name: 'BillingReturnObatKasir',
    component: () => import('@/views/backend/BillingReturnObatKasir/List.vue'),
    meta: {
      menu: "BillingReturnObatKasir",
      pageTitle: 'Billing Return Obat Kasir',
      objName: 'Billing Return Obat Kasir'
      // hasCta: 'true',
    }, 
  },
  
  {
    path: 'billing-jurnal-return-obat/:pageSlug?',
    name: 'BillingJurnalReturnObat',
    component: () => import('@/views/backend/BillingJurnalReturnObat/List.vue'),
    meta: {
      menu: "BillingJurnalReturnObat",
      pageTitle: 'Billing Jurnal Return Obat',
      objName: 'Billing Jurnal Return Obat'
      // hasCta: 'true',
    }, 
  },




  

  

]
export default bo_routes